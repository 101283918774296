.loaderWrapper,
.error {
    backdrop-filter: blur(10px);
    .loader img {
        height: 55px;
    }
    .loader h5{
        text-transform: uppercase;
    }
    .errorContent{
        box-shadow: none !important;
    }
    button {
        background-color: $colorPrimary !important;
        color: $actionColor !important;
    }
}