@import "../../global.scss";

.Splash {
    .close {
        position: absolute;
        font-size: $fontSizeLarge;
        padding: 20px;
        line-height: normal;
        text-indent: 0;
        background-color: transparent;
        color: $fontColorDefault;
        font-weight: initial;
        top: 10px;
        right: 15px;
        i {
            display: none !important;
        }
        &:after {
            @include cross(plus);
            color: $productColor;
            border-color: $productColor !important;
            top: 20px;
            right: 20px;
            margin-top: -20px;
            margin-right: -20px;
            transition: $transitionAll;
            transform-origin: center;
            transform: rotate(0);
            position: absolute;
            font-size: 35px;
        }
    }
}
