.vjs-audio {
	.video-js{
		height: 30px;
		width: 100%;
	}
	.video-js.vjs-paused .vjs-big-play-button{
		display: none;
	}
	.vjs-control-bar{
		display: flex;
	}
}