@import "../../global.scss";

.search-item {
    margin: 10px 0;
    display: flex;
    &:nth-of-type(odd) {
        background-color: $upcomingLaunchesStripeColour;
    }
    &:last-of-type{
        margin-bottom: 60px;
    }
    .product-description {
        padding: 5px;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
    }
    .image {
        width: 100px;
        height: 105px;
        background-position: center;
        background-size: cover;
        position: relative;
    }
    h1 {
        font-size: 14px;
        font-weight: bold;
    }
    h2 {
        font-size: 14px;
        flex-grow: 1;
    }
    .price {
        text-align: right;
    }
    .launchInfo {
        display: none;
    }
    .arrow-wrapper {
        width: 15px;
        display: flex;
        align-items: center;
        .arrow {
            @include chevron(right);
            position: relative;
        }
    }
}
