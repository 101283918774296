@import "../../global.scss";
.learnMoreBg {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    overflow: hidden;
    padding: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $overlayBackground;
    transition: $transitionAll;
    bottom: 0;
    max-height: 100vh;
    z-index: 1000;
}

.learnMore {
    transition: $transitionAll;
    position: absolute;
    background-color: white;
    right: 0;
    padding: 15px;
    margin: 20px;
    text-align: left;
    img {
        width: 100px;
        display: block;
        padding-bottom: 10px;
    }

    .boosted {
        margin-top: 25px !important;
        color: $colorAccent;
        box-shadow: 0 0 15px $colorAccent;
        .button-contents ::after {
            border-color: $colorAccent !important;
        }
    }
    .secondery {
        background-color: #E1E1E1;
        color: #000;
    }

    .form-action {
        margin-top: 10px;
        .action {
            margin: 0;
        }
        padding: 0;
        position: relative;
    }
}

//TRANSITIONS
.loyalty-info-enter,
.loyalty-info-leave.loyalty-info-leave-active {
    max-height: 100vh;
    opacity: 0;
}
.loyalty-info-enter.loyalty-info-enter-active,
.loyalty-info-leave {
    max-height: 100vh;
    opacity: 1;
}
