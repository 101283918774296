@import "../../../global.scss";

div.Preferences.Membership-Preferences {
    span.link {
        text-decoration: underline;
    }
    .sidebar.left .sidebarElements button > div.active {
        border-bottom: none !important;
    }

    .check-option label span.check-box.tick {
        border-color: $fontColorLight;
    }

    .check-option input:checked + label span.check-box,
    .check-option .input:checked + label span.check-box {
        border: 2px solid $colorAccent;
        &::before {
            border-color: $colorAccent;
        }
    }

    .emailSignupLabel {
        margin-bottom: 0;
    }

    .check-option {
        color: $fontColorLight;
    }
    .plusCountdown {
        display: flex;
        align-items: center;
        font-size: 100%;
        color: $fontColorLight;
        padding-bottom: 10px;
        .countdown {
            padding-left: 5px;
            display: inline;
            color: white;
            font-size: 20px;
            .count {
                padding: 1px 3px;
            }
            .colon {
                display: none;
            }
            > span {
                min-width: 22px;
                height: 22px;
                border-radius: 3px;
                background-color: $colorAccent;
                margin: 0 2px;
                > span > span {
                    display: none;
                }
            }
        }
    }

    .achievement-list {
        display: flex;
        flex-flow: wrap;
    }

    .badge-total {
        flex-shrink: 0;
        font-size: 120%;
        color: $colorAccent;
        background-color: $fontColorLighter;
        border-radius: 5px;
        text-align: center;
        padding: 0 10px;
        min-width: 70px;
        min-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            padding: 0;
        }
    }
    .membership-heading {
        display: flex;
        padding-bottom: 20px;
        height: 90px;
        .membership-status {
            flex-shrink: 0;
            font-size: 120%;
            color: $fontColorLight;
            background-color: $fontColorLighter;
            border-radius: 5px;
            text-align: center;
            margin: 0 5px;
            min-width: 70px;
            min-height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h1 {
                padding: 0;
            }
        }
    }

    .membership-logo {
        img {
            padding-right: 20px;
            max-height: 60px;
        }
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
    }
    p {
        margin-bottom: 0;
        color: $fontColorLight;
    }
    h4 {
        background-color: $fontColorLighter;
        color: $fontColorLight !important;
        padding: 5px;
        font-size: 16px;
        border-radius: 5px;
        display: inline-block;
        margin: 10px 0px;
    }

    > .content-scroll {
        padding-left: 50px;
    }

    &.non-member {
        .form-action {
            padding-left: 50px;
        }
        .membership-logo {
            img {
                max-height: 80px;
            }
            padding-bottom: 20px;
        }
        h3 {
            color: $colorAccent !important;
            font-size: 18px;
            margin: 20px 0;
        }
        h4 {
            background-color: $fontColorLight;
            color: $fontColorLighter !important;
            font-size: 16px;
        }
        > .content-scroll {
            padding-bottom: 100px;
        }
        .nativeLinks {
            display: flex;
            justify-content: center;
        }
        .appstore-ios,
        .appstore-android {
            padding-top: 10px;
            height: 60px;
            display: none;
        }
        p {
            font-size: 14px;
        }
    }

    &.member {
        > .content-scroll {
            overflow: hidden;
            padding-bottom: 0px !important;
        }
    }

    .membership-nav {
        display: flex;
        flex-direction: row;
        border-top: $fontColorLighter 2px solid;

        .nav-item {
            flex-grow: 1;
            text-align: center;
            color: $fontColorLight;
            font-size: 18px;
            padding: 6px 0px;
            position: relative;
            span {
                &:before {
                    position: absolute;
                    display: block;
                    top: 0px;
                    z-index: 100;
                    width: 100%;
                    border-top: $colorAccent 2px solid;
                    content: "";
                    transition: all 1s;
                }
            }
            &.inactive {
                span {
                    &:before {
                        opacity: 0;
                    }
                }
            }
            &.active {
                span {
                    &:before {
                        position: absolute;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .content-scroll > :last-child {
        margin-bottom: 20px !important;
    }
    .membership-hub {
        height: 100%;
    }
    .membership-details {
        overflow: hidden;
        height: 100%;
        .react-swipeable-view-container {
            height: 100%;
            .content-scroll {
                margin-bottom: 150px;
            }
        }
    }

    .memberSince {
        padding: 15px 0;
        font-size: 16px;
        color: $colorAccent;
    }
}

.ios {
    .appstore-ios {
        display: block !important;
    }
}

.android {
    .appstore-android {
        display: block !important;
    }
}

@media all and (max-width: 767px) {
    div.Preferences.Membership-Preferences {
        .membership-nav {
            // margin-right: -8vw;
            overflow: scroll;
            justify-content: flex-start;
        }

        .membership-nav div {
            margin-right: 20px;
        }
        > .content-scroll {
            padding-left: 50px !important;
        }
    }
}

.Account-Journey {
    div.Preferences.Membership-Preferences {
        .sidebar {
            display: none;
        }
        > .content-scroll {
            padding-left: 8vw !important;
        }
        &.non-member {
            .form-action {
                padding-left: 8vw;
            }
        }
    }
}
.userTabContainer {
    position: relative;
    margin-top: 10px;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 10px;
    background-color: #f8f8f8;
    padding: 15px;
    overflow: hidden;
    &.inactive {
        height: 65px;
        transition: 0.35s;
    }
    &.active {
        height: 345px;
        transition: 0.35s;
    }
}
// .expand,
// .close {
//     position: absolute;
//     top: 14px;
//     right: 0;
// }
.plusminus {
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    cursor: pointer;

    &.active {
        &:before {
            transform: translatey(-50%) rotate(-90deg);
            opacity: 0;
        }
        &:after {
            transform: translatey(-50%) rotate(0);
        }
    }

    &:before,
    &:after {
        content: "";
        display: block;
        background-color: #333;
        position: absolute;
        top: 50%;
        left: 0;
        transition: 0.35s;
        width: 100%;
        height: 3px;
    }

    &:before {
        transform: translatey(-50%);
    }

    &:after {
        transform: translatey(-50%) rotate(90deg);
    }
}

.user_name {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.user_name div:nth-child(1),
.user_name div:nth-child(2) {
    margin-right: 7px;
}
.user_name div:nth-child(3) {
    color: #1b1c1e;
    font-size: 17px;
}
.anual_wins,
.achievements_title {
    padding-bottom: 10px;
    position: relative;
}
.title_divider {
    position: absolute;
    width: 40px;
    height: 3px;
    background-color: #030405;
    bottom: 2px;
}
.win_counter {
    color: #fd5516;
    font-size: 22px;
    padding-top: 3px;
}
.achievement_container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding-top: 10px;
}
.achievement_container div {
    min-width: 50px;
}
.stickers_container {
    margin-top: 10px;
    display: flex;
    width: 100%;
    overflow-x: scroll;
    height: 60px;
}
.sticker_container {
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.sticker {
    min-height: 57px;
    min-width: 57px;
    max-width: 57px;
    max-height: 57px;
    margin-right: 10px;
    background-color: #eaeaea;
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
}
.sticker img {
    height: 100%;
    width: 100%;
}
.sticker_win_count {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 15px;
    width: 30px;
}
.leaderboard_buttons {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 30px;
}
.leaderboard_buttons button {
    width: 134px;
    height: 31px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: lighter;
}
.leaderboard_buttons button:nth-child(1) {
    margin-right: 10px;
    border: 1px solid #1b1c1e;
}
.leaderboard_buttons button:nth-child(2) {
    background-color: #ff550e;
    color: #ffffff;
    border: unset;
}
.leaderboard_table_wrapper {
    margin-top: 15px;
    font-family: "Lato", sans-serif;
    height: 460px;
}
.leaderboard_table_titles {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 41px;
    margin-bottom: 10px;
}
.left_table_title,
.right_table_title {
    font-size: 20px;
    flex: 1;
    height: 100%;
}
.right_table_title {
    color: #ff600b;
}
.leaderboard_item {
    border-bottom: 2px solid #cdcdcd;
    padding-bottom: 10px;
    padding-top: 15px;
    display: flex;
}
.leaderboard_item_left,
.leaderboard_item_right {
    flex: 1;
    position: relative;
}
.leaderboard_item_right {
    display: flex;
    overflow: scroll;
    margin-left: 10px;
}
.leaderboard_item_right {
    .sticker {
        min-height: 35px;
        min-width: 35px;
        max-width: 35px;
        max-height: 35px;
    }
    img {
        width: 100%;
    }
}
.leaderboard_item_left {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leaderboard_1st_wins,
.leaderboard_2nd_wins,
.leaderboard_3rd_wins,
.leaderboard_regular_placement_wins {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 6px 4.5px;
    border-radius: 0px 0px 15px 15px;
    margin-right: 10px;
    max-width: 100px;
}
.leaderboard_1st_wins {
    background-color: #c7b040;
    color: white;
}
.leaderboard_2nd_wins {
    background-color: #d9d9d9;
    color: white;
}
.leaderboard_3rd_wins {
    background-color: #d99f65;
    color: white;
}
.leaderboard_regular_placement_wins {
    background-color: #030405;
    color: white;
}
.leaderboard_1st,
.leaderboard_2nd,
.leaderboard_3rd {
    padding: 5.5px 0;
    padding-top: 6px;
    color: #ffffff;
    font-weight: bolder;
    border-radius: 14px;
    // width: fit-content;
    display: flex;
    font-size: 12px;

    & > div {
        padding-left: 7px;
        padding-right: 3px;
    }
}
.leaderboard_1st div:nth-child(1),
.leaderboard_2nd div:nth-child(1),
.leaderboard_3rd div:nth-child(1) {
    // padding-left: 8px;
    // padding-right: 8px;
}
.leaderboard_1st div:nth-child(2),
.leaderboard_2nd div:nth-child(2),
.leaderboard_3rd div:nth-child(2) {
    padding-right: 8px;
}
.leaderboard_1st {
    background-color: #b39500b3;
}

.leaderboard_2nd {
    background-color: #d9d9d9;
}

.leaderboard_3rd {
    background-color: #d99f65;
}
.leaderboard_regular_placement {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    max-width: 100px;
    overflow-x: scroll;
}
.regular_placemenet {
    color: white;
    background-color: #030405;
    min-width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    font-size: 10px;
    margin-right: 7px;
    transform: translateY(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
}

.regular_placemenet div {
    position: relative;
}
.leaderboard_item_line {
    position: absolute;
    right: 0;
    top: 0;
    height: 38px;
    width: 1.2px;
    background-color: #1b1c1e;
}
.content-scroll {
    overflow-y: scroll !important;
}
.leaderboard_username {
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
}
#root
    > div
    > div:nth-child(2)
    > div.page-wrapper
    > span
    > div
    > div.content-scroll
    > div
    > div.membership-details
    > div
    > div:nth-child(4)
    > div
    > div.userTabContainer.active
    > div:nth-child(4)
    > div.stickers_container
    > div:nth-child(1)
    > div.sticker
    > img {
    width: 70%;
    margin: 0 auto;
}
.leaderboard_initials {
    background: #ff600a;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 13px;
}
#root
    > div
    > div:nth-child(2)
    > div.page-wrapper
    > span
    > div
    > div.content-scroll
    > div
    > div.membership-details
    > div
    > div:nth-child(4)
    > div
    > div.userTabContainer.active
    > div.leaderboard_buttons
    > a
    > button {
    background-color: #ffffff;
}
.stickerCount {
    font-family: "Lato", sans-serif;
    font-weight: bold;
}
.username_container {
    max-width: 85px;
}
