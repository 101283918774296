@import "../../../global.scss";

div.Payment-Preferences {
	&.inSignupFlow {
		.form-action {
			bottom: 0px; //so appears higher up the page than the journey Skip/Next buttons
            position: relative;
            padding: 0;
        }
        .content-scroll{
           
            .privacy-notice{
                display: none;
            }
            .form-action{
                padding-bottom: 100px;
            }
            >:last-child{
                margin-bottom: 0px;
            }
        }
	}
    .cardtype {
        background-size: contain;
        background-position:0 3px;
        background-repeat: no-repeat;
        border-radius: 3px;
        background-image: url(./payment-card-default.png);
        &.visa,
        &.VISA,
        &.visadebit,
        &.visacredit, 
        &.visastandardcredit,
        &.visastandarddebit,
        &.visapremiumcredit,
        &.visapremiumdebit,
        &.visasuperpremiumcredit,
        &.visasuperpremiumdebit,
        &.visacommercialcredit,
        &.visacommercialdebit,
        &.visacommercialpremiumcredit,
        &.visacommercialpremiumdebit,
        &.visacommercialsuperpremiumcredit, 
        &.visacommercialsuperpremiumdebit, 
        &.visacorporatecredit, 
        &.visacorporatedebit, 
        &.visapurchasingcredit, 
        &.visapurchasingdebit, 
        &.visafleetcredit, 
        &.visafleetdebit, 
        &.visaproprietary, 
        &.vpay,
        &.electron,
        &.visacorporate,
        &.visapurchasing, 
        &.visasignature, 
        &.visabusiness,
        &.visaplatinum, 
        &.visagold, 
        &.visaclassic, 
        &.travelmoney {
            background-image: url(./payment-card-visa.png);
        }
        &.mc,
        &.MASTERCARD,
        &.mcdebit, 
        &.mccredit,
        &.mcstandardcredit, 
        &.mcstandarddebit, 
        &.mcpremiumcredit,
        &.mcpremiumdebit, 
        &.mcsuperpremiumcredit,
        &.mcsuperpremiumdebit, 
        &.mccommercialcredit, 
        &.mccommercialdebit, 
        &.mccommercialpremiumcredit, 
        &.mccommercialpremiumdebit,
        &.mccorporate,
        &.mccorporatecredit, 
        &.mccorporatedebit, 
        &.mcpurchasingcredit, 
        &.mcpurchasingdebit, 
        &.mcfleetcredit, 
        &.mcfleetdebit, 
        &.mcpro {
            background-image: url(./payment-card-mastercard.png);
        }
        &.maestro {
            background-image: url(./payment-card-maestro.png);
        }
        &.amex,
        &.AMERICAN_EXPRESS {
            background-image: url(./payment-card-amex.png);
        }        
    }
    .content-scroll {
        h4 {
            
        }
        .bank-card {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: flex-start;
            border-bottom: $borderDefault ;
            padding-bottom: 5px;
            .cardtype {
                    flex-grow: 0;
                    width:42px;
                    margin-left: auto;
                
            }
            .bank-card-info {
            }
        }
    }
    .group {
         input {
            ~ label:after {
                content: " *";
                color: $colorError;
            }
        }
    }
		
}