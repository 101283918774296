@import "../../global.scss";

div.error {
    backface-visibility: hidden;
    position: absolute;
    top:0;
    width:100%;
    z-index: 100;
    overflow: hidden;
    padding:8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $overlayBackground;
    transition: $transitionAll;
    bottom: 0;
    max-height: 100vh;
    .errorContent{
        background-color: transparent;
        color: $overlayColor;
        box-shadow: $boxShadow;
        max-width: 500px;
        margin: 0 auto;
        min-width: 260px;
    }
    h4 {
        margin: 0;
        padding: 0 0 15px;
    }
    h2 {
        padding: 0 0 15px;
    }
    button {
        border: none;
        margin:0;
        padding:15px 15px;
        width: 100%;
        font-size: 140%;
        text-decoration: none;
        background-color: $fontColorWhite;
        color:$fontColorDefault;
        text-align: left;
        &:hover {
            color:$fontColorDefault;
        }
        .button-contents {
                position: relative;
                &::after {
                    position: absolute;
                    margin: auto;
                    border-color: initial !important;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    @include chevron(right);
                }
            }
    }
 }

 button:hover 
 
//TRANSITIONS
.error-bar-enter, .error-bar-leave.error-bar-leave-active  {
    max-height: 100vh;
    opacity: 0;
}
.error-bar-enter.error-bar-enter-active, .error-bar-leave {
    max-height: 100vh;
    opacity: 1;
}
