@import "../../global.scss";

.loaderWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 1000;    
    background-color: $overlayBackground;
    color: $loaderColor;
    @if $headerCompact == true {   
        top: 0;
    } @else {    
        top: 50px;
    }
    &.app-header {
        @if $headerCompact == true {   
            top: 0;
        } @else {    
            top: 70px;
        }
    }
    .loader {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color:inherit;
        height: 5em;
        h5 {
            color: inherit;
            padding-top: 10px;
        }
        img{
            height: 60px;
        }
    }
}
