@import "../../global.scss";

.social-links {
    padding-top: 10px;
    .link-list {
        margin-left: -5px;
        margin-bottom: 5px;
        > div {
            min-width: 30px;
            min-height: 30px;
            display: inline-block;
            text-align: center;
            > img {
                margin: 5px;
                height: 20px;
            }
        }
    }
}
