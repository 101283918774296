@import "../../global.scss";

.desktop-header .usp, .native-header .usp  {
	position: relative;
	padding-left: 0;
	display: flex;
	padding: 0;
	.countdown {
		align-items: center;
		display: flex;
	}
}


.usp {
	color: $uspCheckoutColor;
	z-index: 1;
	position: fixed;
	height: 70px;
	top: 0;
	left: 0;
	margin: 0;
	padding-left: 70px;
	padding-top: 22px;

	.countdown {
		color: inherit;
		display: inline;

		@if $headerCompact==true {
			text-align: left;
		}

		font-size: 35px;

		.units {
			display: none;
		}
	}

	h4 {
		color: inherit;
		text-align: left;
		display: inline-block;
		padding: 0;
		margin: 0;
		width: 100px;
		font-size: $fontSizeNormal;
		height: 12px;
		margin-left: 10px;

		@if $headerCompact !=true {
			display: none;
		}
	}
}
@media all and (max-width: 320px) {
	.app-logo.with-countdown {
		display: none;
	}
}
