@import "../../global.scss";

.Tutorial {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    &.Onboarding {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100% !important;
        min-height: 100% !important;
        .image-gallery {
            padding-top: 5px;
            padding-bottom: 0 !important;
        }
        .Tutorial{
            height: 100%;
        }
    }
    .image-gallery {
        .image-gallery-slide img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
        div {
            height: 100%;
        }
        .image-gallery-bullets {
            top: -15px;
            bottom: initial;
            height: auto;
            .image-gallery-bullet {
                margin: 5px;
                padding: 2px;
                width: 7px;
                height: 7px;
                box-shadow: none;
                transition: all 0.3s;
                background: $tutorialGalleryBulletInactive;
                border-color: $tutorialGalleryBulletInactive;
                &.active {
                    background: $tutorialGalleryBulletActive;
                    border-color: $tutorialGalleryBulletActive;
                }
            }
            div {
                height: auto;
            }
        }
        padding-top: 70px;
        padding-bottom: 50px;
        height: 100%;
        width: 100%;
        &:after {
            background: none;
        }
    }
    .form-action {
        position: relative;
        background: none;
        padding-top: 0;
    }
    .action {
        margin-bottom: 5px;
    }
    .skip {
        text-align: center;
        padding-top: 5px;
    }
}
