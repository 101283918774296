@import "../../global.scss";

$size-color: $colorAccent;
$border-color: #000;
$btn-transition: opacity .3s;

.video-js {

	.vjs-play-progress {
		background: $size-color;
	}

	.vjs-fullscreen-control.vjs-control{
		display: none;
	}

	.vjs-big-play-button {
		top: 50%;
		left: 50%;
		margin-top: -1.22em;
		margin-left: -1.25em;
		color: $size-color;
		height: 2.5em;
		width: 2.5em;
		border-radius: 50%;
		padding: 15px;
		border: 0.07666em solid $border-color;
		background-color: #2b333f;
		background-color: rgba(43, 51, 63, 0.7);
		line-height: 1.4em;
		.vjs-icon-placeholder:before {
			position: relative;
		}
	}

	&:hover .vjs-big-play-button,
	.vjs-big-play-button:focus {
		border-color: $border-color;
	}

	&.vjs-has-started .vjs-big-play-button {
		display: block;
		opacity: 0;
		transition: $btn-transition;
	}

	&.vjs-paused .vjs-big-play-button {
		opacity: 1;
	}
	.vjs-loading-spinner {
		margin: -41px 0 0 -42px;
		border: 6px solid $colorAccent;
		width: 8.5em;
		height: 8.5em;
		border-radius: 50%;
	}
	
}

