@import "../../global.scss";

.form-payments {
    > ul {
        margin: -10px 0 0 !important;
    }
    .RECURRING {
        max-height: 80px;

        .adyen-CVC-form {
            flex-grow: 1;
            flex-shrink: 1;
            text-align: left;
        }
    }
    label {
        line-height: 140%;
        .name {
            font-family: $fontFamilyBold;
            font-weight: normal;
            span {
                display: block;
                font-family: $fontFamilyMedium !important;
                font-weight: normal !important;
            }
        }
        .logo {
            text-align: right;
            background-size: auto 24px;
            background-position: right;
            min-height: 30px;
            min-width: 52px;
            background-repeat: no-repeat;
            margin: -5px 0;
            &.cardvisa,
            &.cardvisadebit,
            &.cardvisacredit,
            &.cardvisastandardcredit,
            &.cardvisastandarddebit,
            &.cardvisapremiumcredit,
            &.cardvisapremiumdebit,
            &.cardvisasuperpremiumcredit,
            &.cardvisasuperpremiumdebit,
            &.cardvisacommercialcredit,
            &.cardvisacommercialdebit,
            &.cardvisacommercialpremiumcredit,
            &.cardvisacommercialpremiumdebit,
            &.cardvisacommercialsuperpremiumcredit,
            &.cardvisacommercialsuperpremiumdebit,
            &.cardvisacorporatecredit,
            &.cardvisacorporatedebit,
            &.cardvisapurchasingcredit,
            &.cardvisapurchasingdebit,
            &.cardvisafleetcredit,
            &.cardvisafleetdebit,
            &.cardvisaproprietary,
            &.cardvpay,
            &.cardelectron,
            &.cardvisacorporate,
            &.cardvisapurchasing,
            &.cardvisasignature,
            &.cardvisabusiness,
            &.cardvisaplatinum,
            &.cardvisagold,
            &.cardvisaclassic,
            &.cardtravelmoney {
                background-image: url(./payment-card-visa.png);
                background-size: auto 28px;
            }
            &.cardmc,
            &.cardmastercard,
            &.cardmcdebit,
            &.cardmccredit,
            &.cardmcstandardcredit,
            &.cardmcstandarddebit,
            &.cardmcpremiumcredit,
            &.cardmcpremiumdebit,
            &.cardmcsuperpremiumcredit,
            &.cardmcsuperpremiumdebit,
            &.cardmccommercialcredit,
            &.cardmccommercialdebit,
            &.cardmccommercialpremiumcredit,
            &.cardmccommercialpremiumdebit,
            &.cardmccorporate,
            &.cardmccorporatecredit,
            &.cardmccorporatedebit,
            &.cardmcpurchasingcredit,
            &.cardmcpurchasingdebit,
            &.cardmcfleetcredit,
            &.cardmcfleetdebit,
            &.cardmcpro {
                background-image: url(./payment-card-mastercard.png);
                background-size: auto 28px;
            }
            &.cardmaestro {
                background-image: url(./payment-card-maestro.png);
                background-size: auto 28px;
            }
            &.cardamex,
            &.cardamerican_express {
                background-image: url(./payment-card-amex.png);
                background-size: auto 28px;
            }
            &.klarna {
                background-image: url(./payment-klarna.png);
                background-size: auto 28px;
            }
        }
        &.CARD {
            .logo {
                background-image: url(./payment-cards.png);
            }
        }
        &.APPLE_PAY {
            .logo {
                background-image: url(./payment-apple.png);
                background-size: auto 28px;
            }
        }
        form#adyen-encrypted-form {
            display: none;
            flex-grow: 1;
            text-align: right;
            padding-left: 20px;
            .form-horizontal {
                width: 100%;
                padding: 0;
            }
            .form-group.cvc {
                > div.group {
                    float: right;
                    width: 100% !important;
                    height: 34px !important;
                    input {
                        margin-top: 5px !important;
                        &:placeholder-shown ~ label {
                            padding-top: 14px;
                        }
                        &:focus ~ label,
                        &:not(:placeholder-shown) ~ label {
                            padding-top: 0 !important;
                        }
                    }
                }
            }
        }
    }
    input:checked + label {
        form#adyen-encrypted-form {
            display: inline-flex;
            .form-group {
            }
            & ~ .logo {
                display: none;
            }
        }
    }
    .noPayments {
        padding: 10px 0;
    }
}

.cardUnlock {
    text-align: center;
    border-top: #000 2px solid;
    padding-top: 10px;
    .countdown {
        text-align: center;
        padding: 5px 0;
    }
}
@media all and(max-width:320px) {
    .form-payments {
        label {
            .logo {
                background-size: auto 18px;
            }
        }
    }
}
@media all and(min-width:768px) {
    .form-payments {
        padding-bottom: 100px !important;
    }
}
