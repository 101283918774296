@import "../../global.scss";

.desktop-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    height: 70px;
    z-index: 100;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-bottom: 1px grey solid;

    .header-site-nav {
        position: absolute;
        display: none;
        left: 50%;
        transform: translate(-50%, 0);

        a {
            font-size: 105%;
            margin: 0 10px;
        }
    }

    .app-logo {
        margin: 20px;
        height: 24px;
    }

    .right,
    .left {
        display: flex;
    }

    &.solid {
        &.app-header {
            border-top: $appHeaderStandard solid $headerBackground;
        }
        background-color: $headerBackground;
        background: $headerGradient;
    }

    &.app-header {
        border-top: $appHeaderStandard solid $appHeaderColor;
    }
    .miniCart {
        background-color: transparent !important;
        margin: 20px 15px;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border-width: 0px;
        cursor: pointer;
        position: relative;
        &.disabled {
            filter: opacity(0.5);
        }
        > img {
            width: 100%;
            height: 100%;
        }
        .count {
            top: 3px;
            right: 0px;
            background-color: transparent;
            color: #000;
        }
    }
    .button {
        margin: 20px 0;
        padding: 0 15px;
        height: 20px;
        border-width: 0px;
        background-color: $headerIconBackground;
        color: $headerIconColor;
        box-shadow: $textShadow;
        img {
            height: 20px;
        }
        &.disabled {
            filter: opacity(0.5);
        }
    }
}

.Preferences .content-scroll {
    padding-top: 75px !important;
}

@media all and (min-width: 1000px){
    .desktop-header {
        padding: 0 45px;
        .header-site-nav {
            display: flex;
        }
    }
}
