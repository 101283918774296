@import "../../global.scss";

div.UpcomingLaunches {
    .content-scroll>h2 {
        display: none;
    }
    ul {
        list-style: none;
        padding: 0 15px 0 15px;
        margin: 0;
        display: block;
    }
    ul > li:nth-of-type(odd) {
        background-color: $upcomingLaunchesStripeColour;
    }
    &.nav-left {
        .content-scroll {
            padding-top: 70px !important;
            padding: 75px 0 0 20px;
            text-align: right;
        }
    }
    &.nav-bottom {
        .content-scroll {
            padding-top: 100px !important;
            padding-bottom: 60px;
            text-align: right;
            > h2 {
                display: none;
            }
        }
    }
}
.android {
    div.UpcomingLaunches.nav-bottom {
        .content-scroll {
            padding-top: 120px !important;
        }
    }
}
