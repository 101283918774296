@import "../../../global.scss";

div.PreOrders {
	.content-scroll {
		.action {
			width: 100%;
			margin-bottom: 10px;
			&:last-of-type {
				margin-bottom: 0px;
			}
			position: relative;
			&.level-2 > .button-contents::after {
				border-color: $actionBackground !important;
			}
			> .button-contents {
				position: relative;
				padding-right: 10px;
				&::after {
					position: absolute;
					margin: auto;
					border-color: $actionColor !important;
					right: 0;
					top: 0;
					bottom: 0;
					@include chevron(right);
				}
			}
		}
		.form-horizontal {
			.no-raffles {
				height: 100%;

				.background {
					font-size: 400px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					font-family: $fontBold;
					color: $upcomingLaunchesStripeColour;
				}

				.overlay {
					position: absolute;
					left: 50%;
					top: 50%;
					width: 100%;
					transform: translate(-50%, -50%);
				}

				text-align: center;
			}

			ul {
				list-style: none;
				margin: 0;
				display: block;

				li {
					padding: 10px 5px;
					.topRow {
						display: flex;
						
						.productImage {
							max-width: 30%;
							img {
								width: 100%;
								height: 100%;
							}
						}

						.title {
							padding-left: 5px;
						}

						.status {
							margin-left: auto;
						}

						.tierLevel {
							margin-left:auto;
							p {
								font-size: xx-large;
								background-color: $colorAccent;
								border-color: $colorBorder;
								border-radius: 50%;
								color: $colorInput;
								width: 50px;
								height: 50px;
								text-align: center;
								padding-top: 5px;
							}
						}
					}

					.bottomRow {
						&.orderInfo {
							hr {
								border-top: 2px solid black;
								margin-bottom: 10px;
							}
						}

						.progressBar {
							margin: 15px 35px 35px 35px;
						}
					}
				}
			}

			ul > li:nth-of-type(odd) {
				background-color: $upcomingLaunchesStripeColour;
			}
		}
	}

	
}

.step {
	text-align: center;
	padding-top: 12px;
	width: 100px;
}

.RSPBprogressBar {
	background-color: initial !important;
}

.indexedStep {
	color: white;
	margin-left: auto;
	margin-right: auto;
	width: 20px;
	height: 20px;
	font-size: 12px;
	background-color: rgba(211, 211, 211, 0.8);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
