@import "../../../global.scss";

.product-full {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 7px !important;
    position: relative;
    .image {
        display: block;
        font-size: 0;
        max-width: 100%;
        width: 100%;
        padding-top: calc(960 / 640 * 100%);
        background-position: center;
        background-size: cover;
        position: relative;
    }
    .actions {
        .active {
            .active {
                display: block;
            }
            .inactive {
                display: none;
            }
        }
        img {
            &.active {
                display: none;
            }
            position: relative;
            width: 30px;
            height: 30px;
            padding: 3px;
        }
    }
    .productDetails {
        display: flex;
        justify-content: space-between;
        position: absolute;
        padding: 10px;
        top: 0;
        width: 100%;
        h3,
        span {
            font-weight: bold;
        }
    }
    .launchDate {
        padding: 10px;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
    }
    .launchDateMini {
        background-image: url("/img/plp/calendar.svg");
        width: 55px;
        padding-bottom: 55px;
        position: relative;
        box-shadow: none;
        background-color: transparent;
        .content {
            padding-top: 18px;
            color: white;
        }
    }
    .itemFooter {
        .actions {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
        }
        .launchInfo {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 10px;
            text-decoration: underline;
        }
    }
}
