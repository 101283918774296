@import "../../global.scss";

div.splashLogin {
    &.hide{
        display: none;
    }
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 101;
    background-color: $overlayBackground;
    color: $overlayColor;
    background: no-repeat top;
    background-size: cover;
    background-image: url(../../assets/img/login/splash-bg.jpg);
    .splashHeader {
        padding: 10vh 0 0 8vw;
        color: black;
        .description {
        }
        p {
            width: 70%;
            max-width:260px;
            line-height: 150%;
        }
    }
    .splashActions {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 8vw 20px;
        > a {
            display: inline-block;
            width: 65%;
            max-width:260px;
            margin-bottom: 15px;
            text-align: left;
            line-height: 20px;
            padding: 12px 15px;
            box-sizing: border-box;
            min-height: 0;
            .button-contents {
                position: relative;
                &::after {
                    position: absolute;
                    margin: auto;
                    border-color: initial !important;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    @include chevron(right);
                }
            }
            &.login {
                background-color: #ffffff;
                color: #000 !important;
            }
        }
        .splashFooter {
            padding-top: 15px;
            display: flex;
            align-items: baseline;
            color: #ffffff;
            button {
                padding: 0;
                border: none;
                background-color: transparent;
                text-decoration: underline;
                color: inherit;
                display: inline-block;
                vertical-align: baseline;
            }
            span {
                flex-grow: 1;
                text-align: right;
                img {
                    display: inline-block;
                    font-size: 0;
                    vertical-align: bottom;
                    width: 50px;
                    margin-left: 4px;
                    margin-bottom: 0px;
                }
            }
        }
    }
    h1 {
        color: inherit;
        text-transform: uppercase;
        padding-bottom: 15px;
        font-family: $fontThin;
        font-size: $fontSizeLarge * 2;
        width: 50%;
        line-height: 100%;
        img {
            width: 100%;
            max-width: 320px;
        }
    }
}
@media all and (max-width: 767px) and (orientation: landscape) {
    div.splashLogin {
        .splashHeader {
            padding: 15vh 0 0 8vw;
        }
        .splashActions {
            a.action {
                display: inline-block;
                vertical-align: top;
                max-width: 196px;
                ~ a.action {
                    margin-left: 10px;
                }
            }
        }
    }
}
@media all and (min-width: 768px) {
    div.splashLogin {
        .splashActions {
            a.action {
                display: block;
                vertical-align: top;
                max-width: 240px;
                ~ a.action {
                    margin-left: 0px;
                }
            }
        }
    }
}
@media all and (max-width: 320px) {
    div.splashLogin {
        .splashHeader {
            padding: 40px 0 0 8vw;
        }
    }
}
