@import "../../global.scss";

.holdingPage {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0; 
    right: 0;
    z-index: 101;
    background-color: $overlayBackground;
    display: flex !important;
    flex-direction: column;
    color: $overlayColor;
    padding: 8vw 8vw 20px;
    background: no-repeat top;
    background-size: cover;
    background-image: url(../../assets/img/holdingPage/background.jpg);
    .holdingHeader {
        text-align: center;
        color: white;
    }
    .socials {
        flex-grow: 1;
        vertical-align: middle;
        color: white;
        position: relative;
        .socialsContent {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        p {
            width: 100%;
            font-size: 15px;
            line-height: 150%;
            text-align: center;
        }
        .social-links {
            text-align: center;
            p {
                display: none;
            }
            .social-link {
                padding: 5px;
            }
            img {
                height: 30px;
                filter: invert(1);
            }
        }
    }
    .description {
        h2{
            padding-bottom: 15px;
        }
        color: white;
        text-align: center;
        .countdown {
            text-align: center;
        }
    }
    .holdingActions {
        height: 118px;
        > a {
            display: inline-block;
            width: 100%;
            margin-bottom: 15px;
            text-align: left;
            line-height: 20px;
            padding: 12px 15px;
            box-sizing: border-box;
            min-height: 0;
            .button-contents {
                position: relative;
                &::after {
                    position: absolute;
                    margin: auto;
                    border-color: initial !important;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    @include chevron(right);
                }
            }
            &.login {
                background-color: #ffffff;
                color: #000;
            }
        }
    }
    h1 {
        color: inherit;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 15px;
        font-family: $fontThin;
        font-size: $fontSizeLarge * 2;
        line-height: 100%;
        img {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }
}
