@import "../../global.scss";

div.Product {
    .learnMoreBg {
        position: fixed;
    }

    .moreInfoLink {
        padding-bottom: 10px;
    }

    .preorder-item {
        padding: 5px;
        list-style: none;
        .topRow {
            display: none;
        }

        .bottomRow {
            .progressBar {
                margin: 15px 35px 35px 35px;
            }
        }
        .RSPBprogressBar {
            background-color: rgba(211, 211, 211, 0.6) !important;
        }
    }
    .klarna-exclusive {
        position: absolute;
        width: 50%;
        bottom: 15px;
        left: 15px;
    }
    .klarnaTimedDisclaimer {
        border: $colorAccent 1px solid;
        padding: 10px;
        h4 {
            display: inline;
            font-weight: 400;
        }
    }
    &.reload {
        .content-scroll {
            overflow-y: hidden !important;
        }
    }
    .charity-out-of-entries{
        h3{
            padding-bottom: 5px;
        }
        padding: 15px 0;
    }
    .sizeGuide{
        >table{
            width: 100%;
            tr,
            th {
                border: 1px solid #fff;
                padding: 10px;
                text-align: center;
            }
            th {
                background: #565656;
                color: #fff;
                font-weight: 700;
            }
            tr:nth-child(2n + 3) td {
                background: #f2f2f2;
            }
        }
    }
    .content-scroll {
        .content-scroll-fix {
            height: calc(100% + 1px);
        }
    }
    .imageWrapper {
        position: relative;
        .image-gallery-slide {
            //Fix bug on IOS where the mini calendar and gradient would go below the image
            //gallery when scrolled past a certain point
            z-index: -1000 !important;
        }
        .launchDateMini {
            right: 10px;
            bottom: 0px;
            width: 15%;
            padding-bottom: 15%;
            position: absolute;
            background-color: $calendarBackground;
            .content {
                position: absolute;
                color: $colorAccent;
                text-align: center;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .mount h4 {
                    font-size: 100%;
                    text-transform: uppercase;
                }
                .date h4 {
                    font-size: 170%;
                }
            }
        }
    }
    .question {
        font-size: 12.8px;
        .questionText {
            padding-top: 5px;
            font-size: 20px;
        }
    }
    .product-details {
        clear: both;
        padding: 15px 6vw;
        margin: 0;
        overflow: hidden;
        h2 {
            margin: 0;
            padding: 0 0 0 0;
        }
        .description {
            padding-top: 10px;
            padding-bottom: 15px;
            line-height: 150%;
            p {
            }
            video,
            img {
                width: 100%;
            }
        }

        .underlinedTitle {
            width: auto;
            display: inline-block;
            font-size: $fontSizeLarge;
            border-bottom: $borderDefault;
            padding-bottom: 5px;
        }

        .blogCopy {
            padding-top: 10px;
            padding-bottom: 15px;
            line-height: 150%;
            p {
            }
            video,
            img {
                width: 100%;
            }
        }
        .inlineContainer {
            display: grid;
            grid-template-columns: 0.7fr 0.3fr;
            width: 100%;
            .left {
                .product-details-header {
                    display: inline-grid;
                    width: 100%;
                    grid-template-columns: 1fr auto;
                    font-size: $fontSizeLarge;
                    border-bottom: 2px solid $productColor;
                    .subTitle {
                        display: block;
                    }
                    .priceBlock {
                        display: flex;
                    }
                    .price {
                        display: inline-block;
                        align-self: flex-end;
                    }
                }
            }
            .right {
                .buttons {
                    z-index: 1;
                    height: auto;
                    width: 42px;
                    float: right;
                    .actions {
                        position: initial;
                        text-align: right;
                        font-size: 0;
                        display: inline-block;
                        span {
                            width: 34px;
                            height: 34px;
                            display: inline-block;
                            opacity: 1;
                            img {
                                position: relative;
                                height: 34px;
                                width: 34px;
                                padding: 7px;
                                display: block;
                                -webkit-filter: drop-shadow($textShadow);
                                filter: drop-shadow($textShadow);
                                -webkit-backface-visibility: hidden;
                                transition: none;
                                -webkit-transform-style: preserve-3d;
                                &.active {
                                    display: none;
                                }
                            }
                            &.active {
                                .active {
                                    display: block;
                                }
                                .inactive {
                                    display: none;
                                }
                            }
                            &.notify {
                                img {
                                    padding: 7px;
                                }
                            }
                            &.share {
                                img {
                                    padding: 7px;
                                    top: -2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .countdown-date {
        text-align: left;
        .launchDate {
            display: block;
            text-align: left;
            padding-top: 5px;
            padding-bottom: 5px;
            position: relative;
            h4.dateTime {
                color: inherit;
                position: absolute;
                right: 0;
                display: inline;
                font-weight: $fontWeightBold;
            }
        }
        .countdown {
            text-align: left;
            @include countdownSpeed();
            + .launchDate {
                padding-top: 0.5vw;
            }
        }
    }
    .recaptcha {
        margin-bottom: 15px;
    }
    .launchButton {
        text-align: left;
        width: 100%;
        padding: 15px;
        margin-bottom: 15px;
        position: relative;
        .button-contents {
            position: relative;
            padding-right: 10px;
            ::after {
                margin: auto;
                border-color: $productBackground !important;
                right: 0;
                top: 0;
                bottom: 0;
                @include chevron(right);
            }
        }
        &.boosted {
            color: $colorAccent;
            box-shadow: 0 0 15px $colorAccent;
            .button-contents ::after {
                border-color: $colorAccent !important;
            }
        }
        h4 {
            display: inline;
        }
        &:disabled {
            background-color: mix($productBackground, black, 90%);
            color: $productButtonDisabledText !important;
            .button-contents {
                position: relative;
                padding-right: 10px;
                ::after {
                    position: absolute;
                    margin: auto;
                    border-color: $productButtonDisabledText !important;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    @include chevron(right);
                }
            }
        }
    }
    .infoBlocks {
        border-radius: 0;
        margin: 15px 0 0;
        border: 0 none;
        background-color: transparent;
        border-top: $borderDefault;
        &:empty {
            display: none;
        }
        .rc-collapse-header {
            position: relative;
            padding-right: 30px;
            font-size: $fontSizeLarge;
            padding: 15px 0;
            height: auto;
            line-height: normal;
            text-indent: 0;
            background-color: transparent;
            color: $fontColorDefault;
            font-weight: initial;
            i {
                display: none !important;
            }
            &:after {
                @include cross(plus);
                color: $productColor;
                border-color: $productColor !important;
                top: 50%;
                right: 4px;
                margin-top: -11px;
                transition: $transitionAll;
                transform-origin: center;
            }
        }
        .rc-collapse-content {
            padding: 0 0;
            background-color: transparent;
            color: $productColor;
            .rc-collapse-content-box {
                margin: 0;
                line-height: 150%;
                padding-bottom: 20px;
            }
            video,
            img {
                width: 100%;
            }
            p {
            }
            ul {
                margin: 0;
                padding: 0 0 0 16px;
                list-style: square;
                li {
                    margin-bottom: 2px;
                }
            }
        }
        .rc-collapse-item-active {
            .rc-collapse-header {
                &:after {
                    transform: rotate(0deg);
                    margin-top: -11px;
                }
            }
        }
    }
    .productVideo,
    .productAudio {
        .underlinedTitle {
            margin: 0 0 10px;
        }
        video,
        audio {
            width: 100%;
            left: 0;
            right: 0;
            min-width: 100%;
        }
    }
}

// MEDIA queries for landscape products
@media all and (min-width: $desktopWidth) and (orientation: landscape) {
    .Product {
        .content-scroll {
            height: 100% !important;
            overflow: hidden;
            -webkit-overflow-scrolling: none;
        }
        .imageWrapper {
            min-width: 55vw;
			max-width: 55vw;
			overflow-y: scroll;
			position: sticky !important;
			top: 0;
        }
        .content-scroll-fix {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            height: 100% !important;
            overflow: scroll;
            -webkit-overflow-scrolling: none;
            .image-gallery {
                width: 100%;
                min-width: 100%;
                flex-grow: 0;
                flex-shrink: 1;
                &:after{
                    display: none;
                }

                height: 100%;
                .image-gallery-bullets {
                    display: none;
                }
                .image-gallery-slide {
                    transform: none !important;
                    transition: none !important;
                    position: relative;
                }
                .image-gallery-content {
                    z-index: 0;
                    .image-gallery-image {
                        img {
                            top: 0;
                            width: 100%;
                        }
                    }
                }
            }
            .buttons {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 80%;
                flex-grow: 0;
                flex-shrink: 1;
            }
            .product-details {
                top: 0px;
                position: sticky;
                height: fit-content;
                max-height: initial;
                padding: 20px 30px;
                flex-grow: 1;
                display: inline-block;
            }
        }
    }
}