@import "../../global.scss";

.sidebar {
	.backdrop {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: none;
	}
	&.left {
		.bottom {
			display: none !important;
		}
		&.invert {
			.more {
				border-top: #fff 2px solid !important;
				border-right: #fff 2px solid !important;
			}
			button {
				color: #ffffff !important;

				.active {
					border-bottom: 1px solid #ffffff !important;
				}
			}
		}
		transition: width 0.5s, box-shadow 0.5s, background-color 0.75s;
		position: fixed;
		z-index: 100;
		top: 0;
		bottom: 0;
		left: 0;
		width: 32px;
		line-height: 100%;
		color: inherit;
		-webkit-backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		overflow: hidden;
		&.expand {
			.backdrop {
				position: fixed;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				display: block;
			}
			background-color: #ffffff;
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
			width: 80px;
			.sidebarElements {
				.more {
					border-top: #000000 2px solid !important;
					border-right: #000000 2px solid !important;
				}
				height: 90px;
				button {
					color: #000000 !important;
				}
			}
		}

		.sidebarElements {
			width: calc(100vh - 140px) !important;
			max-width: 550px;
			transform: translate(-50%, 0%) rotate(-90deg);
			top: 45%;
			left: 42px;
			position: absolute;
			height: 90px;
			display: inline;
			text-align: center;

			img {
				height: 20px;
				padding-bottom: 4px;
			}

			.row-1,
			.row-2 {
				flex-shrink: 0;
			}

			.row-2-holder {
				.row-2:not(:last-of-type) {
					margin-right: 30px;
				}
				.row-2 {
					display: inline;
				}
				.row-1 {
					display: none;
				}
			}
			.row-1-holder {
				display: flex;
				justify-content: space-between;
				.row-2 {
					display: none;
				}
			}

			.more-wrapper {
				display: inline-block;
				padding: 5px;
				margin: -5px;
				margin-top: 12px;
			}
			.more {
				transition: all 0.5s;
				margin-right: 20px;
				width: 10px;
				height: 10px;
				border-top: #000000 2px solid;
				border-right: #000000 2px solid;
				transform: rotate(135deg);
				&.contract {
					transform: rotate(-45deg);
				}
			}

			button {
				transition: all 0.5s;
				color: $fontColorDark;
				background-color: transparent;
				border: none;
				text-decoration: none;
				position: relative;
				text-align: center;
				display: block;
				height: 35px;
				font-family: $fontDefault;
				line-height: 0;
				display: inline;
				margin-right: 10px;
				padding: 0px !important;

				.nav-item-Featured {
					color: $colorAccent !important;

					&.active {
						border-bottom: 1px solid $colorAccent !important;
					}
				}

				> div {
					display: inline;
					height: 45px;
					line-height: 45px;
					font-size: 14px;

					&.active {
						border-bottom: 1px solid $fontColorDark;
					}
				}
			}
		}
	}
	&.bottom {
		.left {
			display: none !important;
		}
		overflow: hidden;
		position: fixed;
		transition: all 0.5s;
		.sidebarElements {
			width: 100vw;
			height: $sidebarHeight * 2;
			transform: rotate(0deg);
			position: absolute;
			bottom: 0;
			span {
				height: $sidebarHeight;
				display: inline-block;
				text-align: center;
				flex-grow: 1;
				button {
					background-color: transparent;
					border: none;
					text-decoration: none;
					width: 100%;
					height: $sidebarHeight;
					display: inline-flex;
					align-items: flex-start;
					> div {
						height: $sidebarHeight - 2px;
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-bottom: 2px solid transparent;
						&.active {
							border-bottom: 2px solid #ffffff !important;
						}
					}
				}
			}
		}
		bottom: 0;
		left: 0; //Fixes animation bug on ios
		width: 100vw;
		height: $sidebarHeight;
		background-color: $colorPrimary;
		z-index: 50;
		img {
			height: $sidebarHeight - 45px;
		}
		.lable{
			margin-top: 5px;
			font-size: 10px;
		}
		.more-wrapper {
			flex-grow: 1;
			padding-top: 25px;
		}
		.more {
			transition: all 0.5s;
			margin: auto;
			width: 15px;
			height: 15px;
			border-top: #ffff 2px solid;
			border-right: #ffff 2px solid;
			transform: rotate(135deg);
			&.contract {
				transform: rotate(-45deg);
			}
		}
		&.expand {
			.backdrop {
				position: fixed;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				display: block;
			}
			height: $sidebarHeight * 2;
		}
		.row-1-holder {
			text-align: end;
			height: $sidebarHeight;
			.row-1 {
				display: none;
			}
			.row-2{
				width: $sidebarHeight;
			}
		}
		.row-2-holder {
			height: $sidebarHeight;
			display: flex;
			.row-2 {
				display: none;
			}
		}
	}
}
