@import "../../global.scss";

div.Order {
    padding: 15px;
    font-size: 14px !important;
    background-color: rgba($orderBackground, 0);
    .content-scroll {
        margin-bottom: 125px;
        .logo {
            height: 0px;
            padding: 0 15px;
            position: absolute;
            top: 31px;
            img {
                height: 24px;
            }
        }
    }
    h1,
    h2 {
        font-size: 20px;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: 0 0 15px;
    }
    .order-item {
        width: 150px;
        .order-item-image {
            img {
                display: block;
                width: 100%;
                min-height: 150px;
            }
        }
        .order-item-info {
            padding-top: 10px;
            .bottom-block {
                display: flex;
                padding-top: 10px;
                margin-top: 10px;
                border-top: $borderDefault;
                .price {
                    margin-left: auto;
                }
            }
        }
        p.price {
            font-family: $fontFamilyBold;
            span {
                font-family: inherit;
            }
        }
    }
    .order-totals {
        font-weight: 700;
        font-size: 16px;
        padding-top: 15px;
    }
    p {
        margin: 0;
        & ~ p {
        }
    }
    h3 {
        margin: 15px 0 5px;
        font-family: $fontDefault;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        h4 {
            font-weight: 700;
            margin: 0;
        }
    }
}

@media all and (min-width: 768px) {
    div.Order {
        .content-scroll {
            padding-left: 30vw !important;
            padding-right: 30vw !important;
        }
        .form-action {
            padding-left: 30vw !important;
            padding-right: 30vw !important;
        }
    }
}
