@import "../../global.scss";

div.Blog {
    .no-blog {
        position: relative;
        height: 100%;
        .background {
            font-size: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-family: $fontBold;
            color: $upcomingLaunchesStripeColour;
        }
        .overlay {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
        }
        text-align: center;
    }
    h2 {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: $fontSizeNormal;
    }
    img {
        width: 100%;
    }
    .post-list {
        list-style: none;
        padding: 5px 15px 0 0;
        margin: 0;
        display: block;
    }
    &.nav-bottom {
        .content-scroll {
            padding: 75px 0 $sidebarHeight 0;
            text-align: right;
        }
        .post-list{
            padding-right: 0px;
        }
        .title{
            padding-top: 5px !important;
        }
        .title, .excerpt{
            padding: 0 10px 0 10px;
        }
    }
    &.nav-left {
        .content-scroll {
            padding: 75px 0 0 45px;
            text-align: right;
        }
    }
}
