@import "../../../../global.scss";

div.ContentSlider {
	height: 100%;
	.content-scroll>div{
		height: 100%;
		overflow: hidden !important;
	}
	.ContentPage>.content-scroll{
		overflow-x: hidden;
	}
	.react-swipeable-view-container {
        height: 100%;
		.swiper-slide {
			height: 100%;
            overflow: hidden;
			.background-image {
				height: 100%;
				background-position: center;
				background-size: cover;

				.content {
                    backdrop-filter: brightness(0.75);
                    height: 100%;
                    width: 100%;
				}

				.footer {
                    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
					color: white;
					position: absolute;
					bottom: 0;
                    padding: 15px;
                    padding-left: 45px;
					padding-bottom: 60px;
					transition: opacity 0.1s, transform 0.1s;
					width: 100% !important;
					.title {
						font-size: 35px;
					}

					.subTitle {
						font-size: 25px;
					}

					.brand {}
				}
			}
		}
	}
}