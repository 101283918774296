@import "../../global.scss";

#adyen-encrypted-form {
    .exp {
        flex-direction: row;
        flex-wrap: wrap;
        > div {
            width: 33% !important;
            ~ div {
                margin-left: 10px;
            }
        }
        label {
            width: 100%;
        }
        input {
            display: inline-block;
            ~ input {
                position: relative;
                left: 10px;
            }
        }
    }
    .cvc {
        > div {
            width: 33% !important;
        }
    }
}
