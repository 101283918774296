@import "../../../global.scss";

li.blog-category {
	text-align: center;
	display: inline;
	font-family: $fontBold;
	font-size: 16px;
	&::after {
		content: ", ";
	}
	&:last-child::after {
		content: "";
	}
	img {
		height: 18px;
		width: initial !important;
		margin-top: -1px;
	}
}