@import "../../global.scss";

// BOOKMARKS
.Home {
    background-color: $bookmarksBackground;
    color: $bookmarksColor;
}
.Bookmarks {
    background-color: $bookmarksBackground;
    color: $bookmarksColor;
    .content-scroll {
        padding-top: 55px + $headerHeight!important;
    }
}
.app-header {
    .Bookmarks {
        .content-scroll {
            padding-top: 55px + $appHeaderStandard + $headerHeight!important;
        }
    }
}
.android {
    .app-header {
        .Bookmarks {
            .content-scroll {
                padding-top: 55px + $appHeaderAndroid + $headerHeight!important;
            }
        }
    }
}

.no-scroll .content-scroll {
    overflow: hidden;
}

.Bookmarks.bookmarksFalse {
    background-color: $bookmarksBackground;
    color: $bookmarksColor;
    .content-scroll {
        padding-top: 70px + $headerHeight!important;
    }
}
.app-header {
    .Bookmarks.bookmarksFalse {
        .content-scroll {
            padding-top: 70px + $appHeaderStandard + $headerHeight!important;
        }
    }
}
.android {
    .app-header {
        .Bookmarks.bookmarksFalse {
            .content-scroll {
                padding-top: 70px + $appHeaderAndroid + $headerHeight!important;
            }
        }
    }
}

// CALENDAR
.Calendar {
    background-color: $calendarBackground;
    color: $calendarColor;
    .content-scroll {
        padding-top: 70px + $headerHeight!important;
    }
}
.app-header {
    .Calendar {
        .content-scroll {
            padding-top: 55px + $appHeaderStandard + $headerHeight!important;
        }
    }
}
.android {
    .app-header {
        .Calendar {
            .content-scroll {
                padding-top: 55px + $appHeaderAndroid + $headerHeight!important;
            }
        }
    }
}

// PAST LAUNCHES
.PastLaunches {
    background-color: $calendarBackground;
    color: $calendarColor;
    .content-scroll {
        padding-top: 55px + $headerHeight!important;
    }
}
.app-header {
    .PastLaunches {
        .content-scroll {
            padding-top: 55px + $appHeaderStandard + $headerHeight!important;
        }
    }
}
.android {
    .app-header {
        .PastLaunches {
            .content-scroll {
                padding-top: 55px + $appHeaderAndroid + $headerHeight!important;
            }
        }
    }
}

// Blog
.Blog {
    background-color: $blogBackground;
    color: $blogColor;
    .content-scroll {
        padding-top: 55px + $headerHeight!important;
    }
}
.app-header {
    .Blog {
        .content-scroll {
            padding-top: 55px + $appHeaderStandard + $headerHeight!important;
        }
    }
}
.android {
    .app-header {
        .Blog {
            .content-scroll {
                padding-top: 55px + $appHeaderAndroid + $headerHeight!important;
            }
        }
    }
}

// Campaign
.Campaign {
    background-color: $calendarBackground;
    color: $blogColor;
    .content-scroll {
    }
}
.app-header {
    .Campaign {
        .content-scroll {
        }
    }
}
.android {
    .app-header {
        .Campaign {
            .content-scroll {
            }
        }
    }
}

// UPCOMING LAUNCHES
.UpcomingLaunches {
    background-color: $upcomingBackground;
    color: $upcomingColor;
    .content-scroll {
        padding-top: 55px + $headerHeight!important;
    }
}
.app-header {
    .UpcomingLaunches {
        .content-scroll {
            padding-top: 55px + $appHeaderStandard + $headerHeight!important;
        }
    }
}
.android {
    .app-header {
        .UpcomingLaunches {
            .content-scroll {
                padding-top: 55px + $appHeaderAndroid + $headerHeight!important;
            }
        }
    }
}

// PRODUCT
.Product {
    background-color: $productBackground;
    color: $productColor;
    .content-scroll {
        padding-top: 0 + $headerHeight!important;
    }
}

// LOGIN/SIGNUP JOURNEY
.Journey {
	background-color: $accountBackground;
    color: $accountColor;
}

// PREFERENCES
.Preferences {
    background-color: $accountBackground;
    color: $accountColor;
    .content-scroll {
        padding-top: 75px + $headerHeight!important;
    }
}
.app-header {
    .Preferences {
        .content-scroll {
            padding-top: 75px + 20px + $headerHeight!important;
            .content-scroll {
                    padding-top: 0!important;
                }
        }
    }
}
.android {
    .app-header {
        .Preferences {
            .content-scroll {
                padding-top: 75px + $appHeaderAndroid + $headerHeight!important;
                .content-scroll {
                    padding-top: 0!important;
                }
                
            }
        }
    }
}

// ACCOUNT CHECKOUT PAYMENT
.Checkout {
    background-color: $accountBackground;
    color: $accountColor;
    .content-scroll {
        padding-top: 75px + $headerHeight!important;
    }
    &.Order {
        background-color: $orderBackground;
        color: $orderColor;
        .content-scroll {
            padding-top: 75px + $headerHeight!important;
        }
    }
    .loginSignup {
        .content-scroll {
            padding-top: 75px + $headerHeight!important;
        }
    }
}
.app-header {
    .Checkout {
        .content-scroll {
            padding-top: 95px + $appHeaderStandard + $headerHeight!important;
        }
        &.Order {
            .content-scroll {
                padding-top: 75px + $appHeaderStandard + $headerHeight!important;
            }
        }
    }
}
.android {
    .app-header {
        .Checkout {
            .content-scroll {
                padding-top: 95px + $appHeaderAndroid + $headerHeight!important;
            }
            &.Order {
                .content-scroll {
                    padding-top: 75px + $appHeaderStandard + $headerHeight!important;
                }
            }
        }
    }
}

// APP/ERROR
.App {
    background-color: $noAppBackground;
    color: $noAppColor;
    .content-scroll {
        padding-top: 0 + $headerHeight!important;
    }
}
.app-header {
    .App {
        .content-scroll {
            padding-top: 0 + $appHeaderStandard + $headerHeight!important;
        }
    }
}
.android {
    .app-header {
        .App {
            .content-scroll {
                padding-top: 0 + $appHeaderAndroid + $headerHeight!important;
            }
        }
    }
}


.page-wrapper > span > div.Home.page-enter {
    transform: translateX(-200%);
}

.page-wrapper > span > div.Home.page-enter.page-enter-active {
    transform: translateX(-100%);
    transition: all 0.5s ease;
    max-height: 100vh;
    position: relative;

}
