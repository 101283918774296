@import "../../global.scss";

.countdown {
    text-align: left;
    font-size: $fontSizeXXLarge * 1.5;
    line-height: 0;
    transition: none;
    -webkit-transition: none;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transform-style: preserve-3d;
    > span {
        vertical-align: top;
        display: inline-block;
        text-align: center; 
        color: inherit;
        position: relative;
        font-size: inherit;
        transition: none;
        -webkit-transition: none;
        -webkit-backface-visibility: hidden;
        -webkit-transform:translate3d(0,0,0);
        -webkit-transform-style: preserve-3d;
        > span {           
            display: block;
            font-size: inherit;    
            color: inherit;
            display: inline-block;
            font-weight: 700;
            vertical-align: top;
            line-height: 100%;  
            position: relative;  
            transition: none;
            -webkit-transition: none;
            -webkit-backface-visibility: hidden;
            -webkit-transform:translate3d(0,0,0);
            -webkit-transform-style: preserve-3d;        
        }       
    }
    .count {            
        color:inherit;
        font-family: 'fontFamilyCountdown';
        z-index: 1;
        @if $countdownLCD == true{
        font-weight: 400!important; 
        text-shadow: $textShadow;
        @if countdownGhost == true{
            &:before {
                content:'00';
                color:inherit;
                opacity: 0.1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: -1;
                text-shadow: none;
                transition: none;
                -webkit-transition: none;
                -webkit-backface-visibility: hidden;
                -webkit-transform:translate3d(0,0,0);
                -webkit-transform-style: preserve-3d;
            }
            }
        }
        > span {
            @if $displayCountdownLabels == true {
                display: block;
            } @else {
                display: none;
            }
            color:inherit;
            font-size: initial;
            line-height: 14px; 
            font-family: 'fontFamilyMedium'; 
            text-shadow: none;
            margin-top: -3px;
            padding: 0 0 5px 0;
            small {
                font-size: $fontSizeSmall;                
            }
        }
    }
    .colon {    
        font-family: 'fontFamilyCountdown'; 
        color: inherit;
        @if $countdownLCD == true{
        font-weight: 400!important; 
        text-shadow: $textShadow;
            &:before {
                content:':';
                color:inherit;
                opacity: 0.1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: -1;
                color:inherit;
                text-shadow: none;
            }
        }
    }
}