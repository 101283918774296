@import "./global.scss";

body {
    margin: 0;
    padding: 0;
    font-family: $fontDefault;
    font-weight: $fontWeightDefault;
    font-size: $fontSizeDefault;
    color: $fontColorDefault;
    min-height: 100vh;
    background-color: $htmlBackground;
}
#root {
    height: 100%;
    line-height: initial;
    > div {
        height: 100%;
    }
    & ~ div {
        div[data-reactroot] {
            background-color: $htmlBackground !important;
            font-size: inherit !important;
            min-width: 120px;
            // equiv to ul
            > div > div {
                margin: 0;
                padding: 10px 0 !important;
                font-size: inherit !important;
                > div {
                    > span {
                        font-size: inherit !important;
                        * {
                            line-height: auto !important;
                            font-size: inherit !important;
                        }
                        > div > div {
                            color: $fontColorDefault;
                            text-indent: -9px;
                        }
                    }
                }
            }
        }
    }
}
.page-wrapper {
    width: 200%;
    font-size: 0;
    position: absolute;
    bottom: 0px;
    overflow: hidden;
    top: 0px;
    height: 100vh;
    > span {
        font-size: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        > div {
            min-height: 100vh;
            width: 50%;
            display: inline-block;
            vertical-align: top;
            font-size: $fontSizeDefault;
            position: relative;
            opacity: 1;
            left: 0;
            direction: ltr;
            transition: all 0s;
            position: relative;
            // Test to see if breaks app
            max-height: 100vh;
        }
    }
}
.content-scroll {
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .content-scroll {
        overflow: auto;
        position: relative;
        padding: 0 !important;
        padding-top: 0 !important;
    }
}
div.swipable-container {
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto !important;
        align-items: stretch;
        > div {
            height: 100%;
        }
        div.Bookmarks,
        div.Blog,
        div.Calendar,
        div.UpcomingLaunches,
        div.Campaign,
        div.PastLaunches {
            height: 100%;
            position: relative;
            .content-scroll {
                height: 100%;
                position: initial;
            }
        }

        .sidebar {
            position: absolute;
        }
    }
}

div.debugBanner {
    position: fixed;
    z-index: 1000000;
    right: 0;
    background-color: red;
    color: white;
    height: 30px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.splash-screen {
    background-image: url("/img/splash/splash-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100%;
    width: 100%;
}
div.splash-screen {
    background-image: url("/img/splash/splash-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 40px;
    #splash-logo {
        background-image: url("/img/splash/splash-logo.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        max-width: 240px;
        height: 0;
        padding: 10% 0;
        margin: 0 auto;
    }
}

.page-wrapper > span > div.page-enter.page-enter-active {
    transform: translateX(-100%);
    transition: all 0.5s ease;
    max-height: 100vh;
    position: relative;
}
.page-wrapper > span > div.page-leave.page-leave-active {
    transform: translateX(-100%);
    transition: all 0.5s ease;
    max-height: 100vh;
    position: relative;
}

@media all and (min-width: 414px) and (orientation: portrait) {
    body,
    .page-wrapper > span > div {
        font-size: $fontSizeDefault + 1px;
    }
}
@media all and (min-width: 736px) and (orientation: landscape) {
    body,
    .page-wrapper > span > div {
        font-size: $fontSizeDefault + 1px;
    }

    //Hide Klarna as they dont support landscape on tablet
    .native-embed{
        .KlarnaCalculator, .form-option-type.KLARNA {
            display: none;
        }
    }
}

.grecaptcha-badge {
    visibility: hidden;
}


