@import "../../global.scss";

.answer-list {
	list-style-type: none;
	padding: 0;
    text-align: left;
    vertical-align: top;
    margin:11px -4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    li {
        display: inline-block;
        padding:4px;
        vertical-align: top;
        font-size: $fontSizeNormal;
        min-width: 25%;
    }
    label {
        padding: 5px 0;
    }
}
@media all and (min-width:321px) {
    .answer-list {	
        li {
            min-width: 20%;
            button {
                height: 56px;
            }
        }
    }
}
@media all and (min-width:414px) {
    .answer-list {	
        li {
            min-width: 64px;
            button {
                height: 56px;
            }
        }
    }
}