@import "../../global.scss";

div.Preferences {
	.form-option-type.active {
		label:before {
			right: 0 !important;
		}
	}

	.form-option-type {
		label:before {
			transition: all 0.5s;
			right: 10px !important;
		}
	}
	span.link {
        text-decoration: underline;
    }
	.premium-logo{
		height: 45px;
		padding-bottom: 5px;
	}
	.content-scroll {
		padding: 20px 8vw 150px;
		> :last-child {
			margin-bottom: 30px;
		}
	}
	h1 {
		padding-bottom: 20px;
	}
	.greeting {
		margin-bottom: 10px;
		font-size: $fontSizeMedium;
		font-family: $fontFamilyBold;
	}
	margin: 0;
	ul {
		padding: 0;
		list-style-type: none;
		li {
			a {
				p {
					text-decoration: none;
					color: #555555 !important;
				}
			}
		}
	}
	.loggedOutActions {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0 8vw 20px;
		text-align: right;
		> a {
			display: block;
			margin-bottom: 15px;
			text-align: left;
			line-height: 20px;
			padding: 12px 15px;
			box-sizing: border-box;
			min-height: 0;
			position: relative;
			.button-contents {
				position: relative;
				&::after {
					position: absolute;
					margin: auto;
					border-color: initial !important;
					right: 0;
					top: 0;
					bottom: 0;
					@include chevron(right);
				}
			}
			&.login {
				color: white !important;
			}
			&.signup {
				color: white !important;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
@media all and (max-width: 767px) and (orientation: landscape) {
	div.Preferences {
		.loggedOutActions {
			a.action {
				display: block;
				vertical-align: top;
				~ a.action {
					margin-left: 10px;
				}
			}
		}
	}
}

@media all and (max-width: 767px) {
	div.Preferences {
		.left.non-base {
			display: none;
		}
	}
	div.Preferences {
		.right.base {
			display: none;
		}
	}
}
@media all and (min-width: 768px) {
	div.Preferences {
		.content-scroll > :last-child {
			margin-bottom: 0px;
		}
		&.list {
			transform: none !important;
			transition: none !important;
			display: flex;
			padding-left: 10vw;
			padding-right: 10vw;
		}
		.left {
			.welcome {
				display: none;
			}
			padding: 30px;
			min-width: 350px;
			overflow: scroll;
			padding-bottom: 80px;
			.content-scroll {
				padding: 5px;
				position: relative;
			}
		}
		.right {
			flex-grow: 1;
			padding: 0;
			overflow: scroll;
			padding-bottom: 100px;
			.welcome {
				padding-top: 75px;
			}
			.content-scroll {
				padding: 5px;
				position: relative;
				width: 100%;
			}
			.form-action.fixed {
				// position: relative;
				// padding: 20px 0;
			}
		}
		.Tutorial {
			width: 100% !important;
		}
		.PreOrders {
			height: 100%;
			.content-scroll {
				height: 100%;
			}
		}
		.Membership-Preferences {
			.sidebar {
				display: none;
			}
		}
	}
	.Account-Journey {
		#adyen-encrypted-form {
			.form-action.fixed {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}
		.form-action,
		.content-scroll {
			padding-left: 30vw !important;
			padding-right: 30vw !important;

			.form-action.fixed {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}
	}
}
.size_orange {
	color: #FF600B;
}