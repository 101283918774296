@import "../../../global.scss";

div {
    li.product {
        position: relative;

        &.draw-closed {
            opacity: 0.5;
            pointer-events: none;
        }
        .klarna-exclusive {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 50%;
        }
        &.promo {
            width: 100% !important;
            padding-left: 10px;
            padding-right: 15px;
            padding-bottom: 10px;
            .image {
                padding-top: calc(720 / 1280 * 100%);
            }
        }
        hr {
            border: 1px solid $calendarColor;
            margin-top: 2px;
            margin-bottom: 2px;
        }
        vertical-align: top;
        text-align: left;
        h3 {
            color: inherit;
            font-weight: $fontWeightThin;
            font-size: 13px;
            opacity: 0.8;
            display: inline;
            overflow: hidden;
            line-height: 17px;
        }
        h4 {
            color: inherit;
            font-weight: $fontWeightDefault;
            font-size: 13px;
            padding: 0;
        }
        > a {
            display: block;
            color: inherit;
            text-decoration: none;
            position: relative;
            .image {
                display: block;
                font-size: 0;
                max-width: 100%;
                width: 100%;
                padding-top: calc(720 / 640 * 100%);
                background-position: center;
                background-size: cover;
                position: relative;
            }
        }
        .productDetails {
            overflow: auto;
            position: relative;
            z-index: 0;
        }
        .launchInfo {
            padding: 3px 0 5px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            z-index: 1;
            display: inline;
            position: absolute;
            right: 0;
            h4 {
                flex-grow: 1;
            }
        }
        .itemFooter {
            position: relative;
        }
        .subtitle-price {
            font-size: 13px;
            .price {
                margin-left: 8px;
                margin-top: 1px;
                float: right;
            }
        }
        .actions {
            text-align: right;
            font-size: 0;
            position: relative;
            z-index: 1;
            margin-right: -4px;
            span {
                width: 20px;
                height: 20px;
                display: inline-block;
                opacity: 0.75;
                position: relative;
                ~ span {
                    margin-left: 8px;
                }
                img {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    padding: 3px;
                    margin: 0px;
                    line-height: 0;
                    font-size: 0;
                    display: block;
                    -webkit-filter: drop-shadow($textShadow);
                    filter: drop-shadow($textShadow);
                    -webkit-backface-visibility: hidden;
                    transition: none;
                    -webkit-transform-style: preserve-3d;
                    &.active {
                        display: none;
                    }
                }
                &.active {
                    .active {
                        display: block;
                    }
                    .inactive {
                        display: none;
                    }
                }
            }
        }

        .image {
            position: relative;
            .launchDateMini {
                left: 10px;
                top: 0;
            }
        }
    }
}
