@import "../../global.scss";  

.notifyBookmarkButton {
	width:100%;
	.button-contents {
        position: relative;
        &::after {
            position: absolute;
            margin: auto;
            border-color: initial !important;
            right: 0;
            top: 0;
            bottom: 0;
            @include chevron(right);
        }
    }
}