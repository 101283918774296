.loginSignup {
    .form-horizontal {
        height: 100%;
        .form-group.form-text {
            padding-bottom: 20px;
        }
        .spacer {
            padding-bottom: 230px;
        }
        .form-action {
            .form-group.form-text {
                padding-bottom: initial;
            }
        }
    }
    .desktop {
        display: none;
    }

    @media all and (min-width: 768px) {
        .form-action {
            // .form-text {
            //     display: none;
            // }
            // .privacy-notice {
            //     display: none;
            // }
        }
        .spacer {
            padding-bottom: 90px !important;
        }
        .desktop {
            display: block !important;
        }
    }
}
