@import "./global.scss";

@font-face {
    font-family: 'fontFamilyMedium';
    src: url('./styles/fonts/#{$fontFamilyName}/medium/#{$fontFamilyName}-medium-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'fontFamilyBold';
    src: url('./styles/fonts/#{$fontFamilyName}/bold/#{$fontFamilyName}-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'fontFamilyThin';
    src: url('./styles/fonts/#{$fontFamilyName}/thin/#{$fontFamilyName}-thin-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'fontFamilyCountdown';
    src:  url('./styles/fonts/#{$fontFamilyCountdown}/medium/#{$fontFamilyCountdown}-medium-webfont.woff') format('woff');
    font-weight: 400!important;
    font-style: normal;
}

// RESET
html, body {
    overflow: hidden;
    max-width: 100%;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
}
html {
    min-height:100%;
    background-color: $htmlBackground;
}
*:focus {
    outline: none;
}
::-webkit-scrollbar { 
    display: none; 
}
b, strong {
    font-family: $fontBold;
    font-weight: normal;
}

// HEADERS
h1, h2, h3, h4, h5 {
    font-family: $fontDefault;
    font-weight: $fontWeightDefault;
    margin: 0;
    color: inherit!important;
}
h1, .h1 {
    font-size: $fontSizeXLarge;
}
h2, .h2 {
    font-size: $fontSizeLarge;
}
h3, .h3 {
    font-size: $fontSizeMedium;
}
h4, .h4 {
    font-size: $fontSizeNormal;
}
h5, .h5 {
    font-size: $fontSizeNormal;    
}
p {
    margin-top: 0;
    margin-bottom: 15px;
    & ~ p {
        margin-top: 15px;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}
a {
    color:inherit;
    text-decoration: none;
    &:hover, &:active, &:focus, &:visited {
        text-decoration: none!important;
        color:inherit!important;
    }
}
.action {
    font-size: $fontSizeMedium;
    margin: 0 0 20px;
    transition: $transition;    
    background-color: $actionBackground;
    color:$actionColor;
    padding: 10px 15px;
    min-height: 50px;
    transform: translateZ(0);
    text-align: left;
    -webkit-transform: translateZ(0);
    &.level-2{
        border: 2px $actionBackground solid;
        background-color: transparent;
        color: $actionBackground;
    }
    @if $actionBorder == false{
        border:0 none;        
    }
    @if $actionRounded == true{
        border-radius: $actionRoundedRadius;
    }    
    @if $actionShadow == true{
        box-shadow: $actionShadowRadius;
    }
    &[disabled] {
        background-color: #e6e6e6;
        color: #999999 !important;
        box-shadow: none!important;
        &:hover {
            @if $actionShadow == true{
                box-shadow: $actionShadowRadius;
            }
        }
    }
    &:hover {
        @if $actionShadow == true{
            box-shadow: $actionShadowRadiusHover;
        }
    }
}


// WHEN APP DOESNT LOAD
 div#no-app {
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right: 0;  
    background-color: $noAppBackground;
    color: $noAppColor;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: stretch;
    .content {
        color: inherit;
        width: auto;
        justify-content: center;
        padding: 0 8vw;
        display: flex;
        flex-direction: column;     
        h1 {
            color: inherit;
        }
        p {
            color: inherit;
            opacity: 0.75;
        }
        .sad {  
            @if $errorPageSmiley == true {                
                display: block;
            } @else {
                display: none;
            }
            padding-bottom: 15px;
            opacity: 0.75;
            > div {
                display: inline-block;
                vertical-align: top;
                &.four {
                    font-size: 74px;
                    font-family: $fontFamilyThin;
                    line-height: 58px;
                }
            }
            div.container {
                position: relative;
                height: 50px;
                width: 50px;
                display: inline-block;
                margin: 0 5px 10px;
                cursor: pointer;
                div {
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    width: 50px;
                    border: 3px solid $noAppColor;
                    color: $noAppColor;
                    padding: 0 0 8px;
                    border-radius: 50%;
                    margin: 0 auto;
                    font-size: 24px;
                    font-family: "comic sans ms",  "comic sans", Arial, Helvetica;
                      transition: color .25s linear;
                      transition: border-color .25s linear;
                      transform: rotate(90deg);
                    animation: shake 1s ease-in-out infinite alternate;
                    -o-animation: shake 1s ease-in-out infinite alternate;
                    -ms-animation: shake 1s ease-in-out infinite alternate;
                    -moz-animation: shake 1s ease-in-out infinite alternate;
                    -webkit-animation: shake 1s ease-in-out infinite alternate;
                }
            }
        }
    }        
}

#hero-iframe-container{
    display: none;
}

@keyframes shake {
    0% {
        padding: 2px 0 0;
    }
    100% {
        padding: 0 0 2px; 
        line-height: 30px;
    }
}
@-moz-keyframes shake {
    0% {
        padding: 2px 0 0;
    }
    100% {
        padding: 0 0 2px; 
        line-height: 30px;
    }
}
