@import "../../global.scss";

div.PastLaunches {
    &.full {
        padding-top: 20px;
        .calendarMonthLabel {
            display: none;
        }
        .calendarMonth {
            padding: 0;
        }
    }
    &.nav-left {
        .content-scroll {
            padding: 75px 0 0 35px;
            text-align: right;
        }
    }
    &.nav-bottom {
        .content-scroll {
            padding-bottom: $sidebarHeight;
            text-align: right;
        }
    }
    ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
        display: block;
    }
    ul {
         ul {
            text-align:left;
            padding: 15px 7.5px 5px 2.5px;
             overflow: hidden;
             font-size: 0;
            > li {
                width: 50%;
                display: inline-block;
                vertical-align: top;
                padding: 5px 7.5px 15px;
                font-size: initial;
                &.calendarMonthLabel {
                    display: block;
                    width: auto;
                    text-align:right;
                    float: none;
                    font-size: initial;
                    h4 {
                        font-size: $fontSizeSmall;
                    }
                }
            }
        }
        &:first-of-type {
            padding-top: 0;
            ul:first-of-type {
                 li.calendarMonthLabel {
                    padding-top: 10px;
                 }
            }
        }
    }
}
@media all and (min-width:600px) {
    div.PastLaunches {
        li.product {
            width: 50%;
            .overlay {
                padding-left: 4vw;
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
@media all and (min-width:320px) and (orientation: landscape) {
    div.PastLaunches {
        li.product {
            width:33.33%;
            .overlay {
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
@media all and (min-width:600px) and (orientation: landscape) {
    div.PastLaunches {
        li.product {
            width: 50%;
            .overlay {
                padding-left: 4vw;
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
@media all and (min-width:800px) and (orientation:landscape) {
    div.PastLaunches {
        li.product {
            width: 20%;
            .overlay {
                .countdown {
                    font-size: $fontSizeXXLarge * 2.5;
                }
            }
        }
    }
}

@media all and (min-width:1024px) and (orientation:portrait) {
    div.PastLaunches {
        li.product {
            width:50%;
            .overlay {
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
@media all and (min-width:1366px) {
    div.PastLaunchesPastLaunches {
        li.product {
            width:16.66%;
            .overlay {
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}

.page-wrapper > span > div.PastLaunches.page-leave {
    transform: translateX(0);
}

.page-wrapper > span > div.PastLaunches.page-leave.page-leave-active {
    transform: translateX(100%);
    transition: all 0.5s ease;
    max-height: 100vh;
    position: relative;

}