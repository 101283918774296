@import "../../global.scss";

div {
    li.upcoming-launch {
        text-align: left;
        padding: 15px;
        .top{
            font-size: 115%;
            display: flex;
            justify-content: space-between;
            img{
                height: 30px;
            }
            .subtitle, .launchDate{
                font-family: $fontBold;  
            }
            .title{
                font-weight: $fontWeightThin;
            }
        }
        .bottom{
            padding-top: 20px;
            font-size: 60%;
            display: flex;
            >div{
                flex-grow: 1;
                flex-basis: 0;
                min-width: 0;
            }
            h3{
                padding-bottom: 2px;
                font-weight: 700; 
            }
        }
    }
}
