.collapsibleSection {
    > .title {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        > .form-header {
            flex-grow: 1;
        }
        > button {      
            border:0 none;
            padding: 0;
            background: transparent;
            color:inherit;
            display: inline-block;
            padding: 4px 0;
            text-decoration: underline;
            &:disabled {
                opacity: 0.4;
            }
        }
    }
    .form-horizontal {
       p {
            padding-bottom: 10px;
            line-height: 150%;
           display: block;
           width:auto;
           max-width: none; 
        }
    }
}