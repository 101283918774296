@import "../../global.scss";

.image-gallery {
    position: relative;
    width: 100%;
    background-color: $imageGalleryBackground;
    z-index: 0;
    min-height: 300px;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 33%;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        z-index: 3;
        pointer-events: none;
        opacity: 0.2;
    }

    .image-gallery-bullets {
        position: absolute;
        bottom: -30px;
        right: 8px;
        left: auto;
        width: auto;
        text-align: left;
        margin: 0;
        padding: 10px;
        pointer-events: none;
        line-height: 0;
        .image-gallery-bullet {
            margin: 5px;
            padding: 2px;
            width: 5px;
            height: 5px;
            box-shadow: none;
            transition: all 0.3s;
            background: $imageGalleryBulletInactive;
            border-color: $imageGalleryBulletInactive;
            &.active {
                background: $imageGalleryBulletActive;
                border-color: $imageGalleryBulletActive;
            }
        }
    }
    a.image-gallery-fullscreen-button {
        overflow: hidden;
        text-align: center;
        padding: 20px;
        line-height: 0;
        &:before {
            line-height: 0;
            padding: 50% 0;
            text-align: center;
            margin: 0;
            font-size: 16px;
            text-shadow: $textShadow;
        }
    }
}
