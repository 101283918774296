@import "../../global.scss";

div.Search {
    padding-top: 75px !important;
    padding: 20px 8vw 0;
    display: flex !important;
    flex-direction: column;
    .loading {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        min-height: 90%;
        img {
            width: 65px;
        }
    }

    .search-group {
        padding: 0 15px;
        background-color: $upcomingLaunchesStripeColour;
        display: flex;
        flex-direction: row;
        border-radius: 50px;
        margin-bottom: 10px;
        input {
            margin-top: 5px;
            width: 100%;
            font-size: 16px !important;
            &::-webkit-input-placeholder {
                font-size: 16px;
                color: $fontColorLighter;
            }

            &:focus {
                color: black;
                border-color: transparent;
            }
        }
        img {
            height: 20px;
            margin: 10px 10px 10px 0px;
        }
    }
    .search-body {
        height: 100%;
        padding-bottom: 50px;
        #search-result {
            height: 100%;
            > div {
                height: 100%;
            }
        }
        .trending-now {
            h1 {
                margin-bottom: 5px;
            }
            .trending-item {
                font-size: 14px;
                margin-left: 8px;
                margin-bottom: 8px;
            }
        }
    }
}

@media all and (min-width: 768px) {
    div.Search {
        padding-left: 30vw !important;
        padding-right: 30vw !important;
    }
}
