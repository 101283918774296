@import "../../global.scss";

div {
	li.blog-post {
		text-align: left;
		padding-top: 10px;
		padding-bottom: 35px;

		.category-list {
			list-style: none;
			padding: 5px 0 0 0;
			text-align: center;
		}

		.title {
            font-family: $fontBold;
			vertical-align: top;
			font-size: 18px;
		}
	}
}