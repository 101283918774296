@import "../../global.scss";
.miniCart {
    top: 0;
    right: 0;
	background-color: #222;
    > img {
        height: 50px;
        width: 50px;
        &.loader {
            padding:15px;
        }
    }
    > .count {
        color: white;
        position: absolute;
        top: 6px;
        right: 6px;
        display: block;
        text-align: center;
        background-color: $colorAccent;
        width:20px;
        border-radius: 100%;
        height:20px;
        font-weight: 700;
        font-size: 10px;
        line-height: 21px;
    }
    .dropdown {
        background-color: white;
        min-width: 250px;
        min-height: 200px;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 5;
        box-shadow: 0 0 15px rgba(0,0,0,0.15);
        padding:15px;
        transition: opacity 200ms ease-in;
        .miniCartHeader {
            border-bottom: 1px solid #efefef; 
            padding-bottom: 10px;
            h5 {
                margin:0;
                padding-bottom: 5px;
            }
            .countdown {
                > span  {                    
                    font-size: 20px;
                }
            }
        }
        .miniCartItems{
            text-align: left;
            ul{
                padding:0;
                margin: 0;
                display: block;
                li {
                    list-style-type: none;
                    border-bottom: 1px solid #efefef;
                    padding: 10px 0;
                    text-align: center;
                    h5{
                        margin: 0;
                        font-weight: 700;
                    }
                }
            }
        }
        .footer {
            overflow: hidden;
            padding: 10px 0;
            label {
                padding: 0 5px 0 0;
                margin: 0;
                float :left;
            }
            span {
                float : right;
                text-align: right;
            }
            .footer-line {
                overflow: hidden;
            }
        }
        > button {
            width: 100%;
            padding:10px;
            font-size: 14px;
        }
    }  
}

.missingPopup{
    position: absolute;
    background-color: white;
    padding: 10px;
    z-index: 100;
    right: 10px;
    margin-top: -15px;
    border: 1px black solid;
    text-align: center;
    h5{
        padding-top: 5px;
        opacity: 0.5;
        text-decoration: underline;
    }
} 


// TRANSITIONS
.miniCart-enter, .miniCart-leave.miniCart-leave-active {
  opacity: 0.01;
}
.miniCart-enter.miniCart-enter-active, .miniCart-leave {
  opacity: 1;
}
