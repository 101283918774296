@import "../../global.scss";

div.debugMenu {
    backface-visibility: hidden;
    position: absolute;
    top:0;
    width:100%;
    z-index: 100;
    overflow: hidden;
    padding:8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $overlayBackground;
    transition: $transitionAll;
    bottom: 0;
    max-height: 100vh;
    z-index: 1000;
    color: $overlayColor;

    .bypassCache{
        background-color: white;
    }
    .button-contents {
        position: relative;
        &::after {
            position: absolute;
            margin: auto;
            border-color: initial !important;
            right: 0;
            top: 0;
            bottom: 0;
            @include chevron(right);
        }
    }

    .close-button {
        margin:0;
        padding:15px 15px;
        width: 100%;
        font-size: 140%;
        text-decoration: none;
        background-color: $fontColorWhite;
        color:$fontColorDefault;
        text-align: left;
        .button-contents {
                position: relative;
                &::after {
                    position: absolute;
                    margin: auto;
                    border-color: initial !important;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    @include chevron(right);
                }
            }
        }
    h1 {
        margin: 0;
        padding: 0 0 15px;
    }
}
 
//TRANSITIONS
.debug-bar-enter, .debug-bar-leave.debug-bar-leave-active  {
    max-height: 100vh;
    opacity: 0;
}
.debug-bar-enter.debug-bar-enter-active, .debug-bar-leave {
    max-height: 100vh;
    opacity: 1;
}
