@import "../../../global.scss";

div.Delivery-Preferences {
	div.storeSelection{
		padding: 10px 0 15px;
		label {
			font-family: $fontFamilyMedium;
			font-weight: $fontWeightDefault;
			margin: 0px 0 0 0!important;
		}
		div.closeButton {
			padding: 15px 40px 15px 15px;
			margin: 10px 0;
			border: $borderDefault;
			position: relative;
			> a {
				width:10px;
				height: 10px;
				display: block;
				@include minusplusremove(remove);
				position: absolute;
				right: 12px;
				top: 50%;
				margin-top: -5px;

			}
		}
	}
	.locale-selector {
		padding: 0;
		ul {
			margin-top: -10px;
			&.empty {
				margin: 0;
			}
		}
	}
}
