@import "../../global.scss";

@if $headerCompact == true {
    .header {
        &.solid {
            &.app-header {
                border-top: $appHeaderStandard solid $headerBackground;
            }
            background-color: $headerBackground;
            background: $headerGradient;
        }
        position: fixed;
        height: 70px;
        z-index: 100;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        &.app-header {
            border-top: $appHeaderStandard solid $appHeaderColor;
        }
        .app-logo {
            position: absolute;
            padding: 0 15px;
            top: 25px;
            right: 0px;
            height: 24px;
        }
        .back {
            z-index: 2;
            position: absolute;
            display: block;
            width: 43px;
            height: 43px;
            border-radius: 100%;
            border-width: 0px;
            top: 16px;
            &.right {
                right: 16px;
            }
            &.left-offset {
                left: 70px;
            }
            &.left {
                left: 16px;
            }
            background-color: $headerIconBackground;
            color: $headerIconColor;
            box-shadow: $textShadow;
            img {
                width: 45px;
                height: 45px;
                position: absolute;
                top: -1px;
                left: -1px;
            }
        }
        .miniCart {
            position: absolute;
            display: block;
            width: 45px;
            height: 45px;
            border-radius: 100%;
            top: 15px;
            left: 70px;
            border-width: 0px;
            background-color: $headerIconBackground;
            color: $headerIconColor;
            box-shadow: $textShadow;
            > img {
                width: 100%;
                height: 100%;
            }
            .count {
                top: -4px;
                right: -4px;
            }
        }
        .search{
            left: 65px !important;
            border: 2px black solid !important;
            padding: 10px;
            img{
                width: 25px !important;
                height: 25px !important;
                top: 8px !important;
                left: 8px !important;
            }
        }
        .logo {
            position: absolute;
            display: block;
            width: 43px;
            height: 43px;
            border-radius: 100%;
            top: 16px;
            left: 16px;
            border-width: 0px;
            background-color: $headerIconBackground;
            color: $headerIconColor;
            box-shadow: $textShadow;
            &.right{
                left: initial;
                right: 16px
            }
            img {
                display: none;
                &.icon {
                    display: block;
                    height: 45px;
                    width: 45px;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    &.icon-account {
                        padding: 0 0px;
                    }
                    &.plus{
                        width: 60px;
                    }
                }
            }
        }
    }
} @else {
    .header {
        width: 100%;
        height: 70px;
        background-color: $headerBackground;
        background: -webkit-linear-gradient(
            top,
            rgba($headerBackground, 1) 0%,
            rgba($headerBackground, 1) 93%,
            rgba($headerBackground, 0) 100%
        );
        color: $headerColor;
        text-align: center;
        position: fixed;
        z-index: 100;
        &.app-header {
            height: 70px;
            border-top: $appHeaderStandard solid $appHeaderColor;
        }
        .app-logo {
            position: absolute;
            padding: 0 15px;
            top: 25px;
            right: 190px;
            z-index: 200;
        }
        .back {
            z-index: 2;
            font-size: 2em;
            color: white;
            float: left;
            position: absolute;
            top: 0;
            left: 0;
            height: 50px;
            width: 50px;
            line-height: 50px;
            img {
                width: 100%;
                padding: 5px;
                box-sizing: border-box;
            }
        }
        .logo {
            display: inline-block;
            img {
                display: block;
                max-width: 300px;
                height: 50px;
                padding: 10px 0;
                &.icon {
                    display: none;
                }
            }
        }
        img {
            vertical-align: initial;
        }
        &.fixfixed {
            position: absolute;
        }
    }
}
.android {
    .header {
        &.app-header {
            border-top-width: $appHeaderAndroid;
        }
    }
}
