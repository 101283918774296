@import "../../global.scss";

div.KlarnaCalculator {
    display: flex;
    margin: 7px 0;
    height: 50px;
    .klarna-badge {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
    .copy {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .disclaimer{
            font-size: 10px;
            text-decoration: underline;
        }
    }
}
