@import "../../global.scss";

.locale-selector {
	label.selected-country {
		font-size:$fontSizeSmall!important;
		padding-top: 0;
		padding-bottom: 0;
        margin-bottom: 0;
		color: #000000;
		font-weight: normal;
		pointer-events: none;
		left: 0px;
		top: 0;
		z-index: 0;
        & + ul {
            margin-top: 0!important;
        }
	}
}