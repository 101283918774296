div.Account-Journey {
    height: 100%;
	.journeyScreen {
		visibility: hidden;
		opacity: 0;
        height: 100%;
		&.active {
			visibility: visible;
			opacity: 1;
		}
        > div {
            height: 100%;
            > .Preferences {
                height: 100%;

            }
        }
	}
}


/**
	Below fixes a bug with the login transition
	On login the app seems to route twice. This 'hides' this problem

**/
.page-wrapper > span > div.Journey.page-enter.page-enter-active:nth-child(2) {
		display: none!important;
        transform: translateX(0%);
        transition: none;
        max-height: 100vh;
    	position: relative;

}
.page-wrapper > span > div.Journey.page-leave.page-leave-active {
        transform: translateX(0%);
        transition: none;
        max-height: 100vh;
        position: relative;
}