// SETTINGS ==============================/


// VARS =============================/
// Is LCD Stylefont being used for countdown timers?
$countdownLCD: true; 
// Should we have 'ghost' digests behind the actual coundown
$countdownGhost: false;
// Show labels on countdown timer
$displayCountdownLabels: true;
// Main Font Family
$fontFamilyName: acumin-pro;
// Backup Google Family
$fontFamilyGoogleBackup: Lato;
// Countdown Family
$fontFamilyCountdown: digital-dismay;
// Compact User section (not fixed header bar)
$headerCompact: true;
// Use actual material inputs rather than our fake ones
$materialOverwrite: true;
// Use Smiley on error page
$errorPageSmiley:true;

$formMaxWidth: 348px;

// DO NOT CHANGE
$fontFamilyThin: 'fontFamilyThin';
$fontFamilyMedium: 'fontFamilyMedium';
$fontFamilyBold: 'fontFamilyBold';
$fontFamilyExtraBold: 'fontFamilyExtraBold';
$fontFamilyBackup: '#{$fontFamilyGoogleBackup}', Arial, sans-serif;
$fontThin: 'fontFamilyThin', $fontFamilyBackup;
$fontDefault: 'fontFamilyMedium', $fontFamilyBackup;
$fontBold: 'fontFamilyBold', $fontFamilyBackup;
$fontExtraBold: 'fontFamilyExtraBold', $fontFamilyBackup;
$fontWeightThin: 300;
$fontWeightDefault: 400;
$fontWeightSemi: 500;
$fontWeightBold: 700;
$fontWeightBlack: 900;
// END DO NOT CHANGE

$fontSizeDefault: 13px;
$fontSizeSmall: 80%;
$fontSizeNormal: 100%;
$fontSizeMedium: 120%;
$fontSizeLarge: 150%;
$fontSizeXLarge:  180%;
$fontSizeXXLarge: 210%;

$colorAccent: #000000;
$colorPrimary: #343434;
$colorSecondary: #282828;
$colorError: #C91515;
$colorValid: #2ECC7F;
$colorBorder:#000000;
$colorInput: #EEEEEE;
$colorBorderFocus: #000000;

$tutorialGalleryBulletActive: #363636;
$tutorialGalleryBulletInactive: #E1E1E1;

$imageGalleryBulletActive: #000000;
$imageGalleryBulletInactive: #CCCC;

$upcomingLaunchesStripeColour: #EFEFEF;

$fontColorDefault: #000000;
$fontColorDark: #000000;
$fontColorLight: #777777;
$fontColorLighter: #EFEFEF;
$fontColorWhite: #FFFFFF;

$noAppBackground: #282828;
$noAppColor: #FFFFFF;
$htmlBackground: #FFFFFF;
$homeBackground: #282828;
$homeColor: #FFFFFF;
$accountBackground: #FFFFFF;
$accountColor:#000000;
$calendarBackground:#FFFFFF;
$calendarColor:#000000;
$upcomingBackground:#FFFFFF;
$upcomingColor:#000000;
$bookmarksBackground:#FFFFFF;
$bookmarksColor:#000000;
$blogBackground:#FFFFFF;
$blogColor:#000000;
$tutorialBackground:#FFFFFF;
$tutorialColor:#000000;
$bookmarkOverlayBackground: rgba(0,0,0,0.2);
$bookmarkOverlayColor: #FFFFFF;
$productBackground: #FFFFFF;
$productColor:#000000;
$productButtonDisabledText:#999999;

$orderBackground: #FFFFFF;
$orderColor:#000000;

$overlayBackground: rgba(252,252,252,0.9);
$overlayColor: #000000;
$loaderColor: #000000;

$headerBackground: #FFFFFF;
$headerColor: #FFFFFF;
$headerIconBackground: #FFFFFF;
$headerIconColor: #FFFFFF;
$headerBasketColor: #282828;
$headerGradient: #FFFFFF;

$uspBackground: #FFFFFF;
$uspColor: #FFFFFF;
$uspCheckoutBackground: #FFFFFF;
$uspCheckoutColor: #000000;

$inputBorders:false;
$inputPlaceholders: false;
$inputInvertedLabels: true;
$inputMaterialStyle: true;
$inputColor: $fontColorDefault;
$inputBackground: #FFFFFF;
$inputValidation: false;

$actionBorder: false;
$actionRounded: false;
$actionShadow: false;
$actionBackground: $colorPrimary;
$actionColor: #FFFFFF;
$actionLogoutBackground: #343434;
$actionLogoutColor: #FFFFFF;
$actionBackgroundDisabled: #CCCCCC;
$actionColorDisabled: lighten($actionBackgroundDisabled, 10%);
$actionRoundedRadius: 4px;
$actionShadowRadius: 0 3px 6px rgba(0,0,0,0.25);
$actionShadowRadiusHover: 0 1px 3px rgba(0,0,0,0.15);

$borderDefault: 2px solid $colorBorder;

$borderInput: 2px solid $colorInput;

$textShadow:  0px 0px 0px rgba(0,0,0,0);
$boxShadow:  0px 0px 15px rgba(0,0,0,0.1);

// Transitions
$transitionTime: 0.3s;
$transition: background-color $transitionTime, color $transitionTime, border $transitionTime, opacity $transitionTime, font-size $transitionTime, box-shadow $transitionTime;
$transitionAll: all $transitionTime;

// Product Page
$imageGalleryBackground: #FFFFFF;
$buttonSelectedBackground: $actionBackground;
$buttonSelectedColor: $actionColor;

// Image Ratios (needed to calculate gallery on landsacpe PDP)
$galleryImageWidth:1200;
$galleryImageHeight:1348;
$galleryRatio: $galleryImageHeight/$galleryImageWidth;
$galleryRatioInverse: $galleryImageWidth/$galleryImageHeight;

// App Header
$appHeaderStandard: 0px;
$appHeaderAndroid: 0px;
$appHeaderColor: transparent;

//Sidebar
$sidebarHeight: 70px;

// MIXINS =========================/
@mixin chevron($dir) {
    content: '';
    display: block;
    border-bottom:  $borderDefault;
    border-right: $borderDefault;
    border-color:  $colorBorderFocus;
    width: 7px;
    height: 7px;
    position: absolute;
    transform-origin: center;
    z-index: 1;
    @if $dir == up {
        transform: rotate(-135deg);
    } @else if $dir == down {
        transform: rotate(45deg);

    } @else if $dir == left {
        transform: rotate(135deg);

    } @else if $dir == right {
        transform: rotate(-45deg);
    }
}
@mixin cross($type) {
    content: '\2715';
    display: block;
    position: absolute;
    transform-origin: center;
    z-index: 1;
    @if $type == plus {
        transform: rotate(45deg);
    }
    @if $type == cross {
        transform: rotate(0deg);
    }
}
// PLUS MINUS REMOVE ICONS
@mixin minusplusremove($type) {
    position: relative;
    &:before {
        content: '';
        display: block;
        background-color: $accountColor;
        height: 2px;
        position: absolute;
        top:50%;
        left:0;
        right:0;
        margin-top: -1px;
    }
    &:after {
        content: '';
        display: block;
        background-color: $accountColor;
        position: absolute;
        left:50%;
        margin-left:-1px;
        top:0;
        width:2px;
        bottom:0;
    }
    @if $type == minus {
        &:after {
            display: none;
        }        
    } @elseif $type == plus {
        
    } @elseif $type == remove {
        transform: rotate(45deg);
    }
}

// COUNTDOWN PULSING SPEEDS
@mixin countdownSpeed() {
    @keyframes pulse { 
        from { 
          opacity: 0.5; 
        }         
        to {
            opacity: 1;
        }
    }
    &.fast {      
        animation: pulse 1s infinite  alternate ease-out ;
        
        &.faster {
            animation: pulse 0.5s  infinite  alternate ease-out ;                            
        }
    }
}

// COMPACT HEDER HEIGHTS
$headerHeight:0px;
@if $headerCompact == true {
    $headerHeight:10px;
} @else {
    $headerHeight:50px;    
}

$desktopWidth:768px;