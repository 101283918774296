@import "../../global.scss";

.size-list {
	list-style-type: none;
	padding: 0;
    text-align: left;
    vertical-align: top;
    margin:11px -4px;
    /*display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;*/
    h4 {
        text-transform: uppercase;
        margin: 0;
        padding:0 4px 10px;
        opacity: 0.5;
        font-size: $fontSizeNormal;
        width: 100%;
    }
    li {
        display: inline-block;
        padding:4px;
        vertical-align: top;
        font-size: $fontSizeNormal;
        min-width: 25%;
        button {
            width:100%;
            cursor: pointer;
            text-align: center;
            display: block;
            width: 100%;
            padding: 0px 15px;
            height: 50px;
            line-height: 120%;
            background-color:rgba(0,0,0,0.1);
            transition: $transition;
            border:0 none;
            position: relative;
            transform: translateZ(0);
            -webkit-transform: translateZ(0);
            &.selected{
                background-color: $buttonSelectedBackground;
                color: $buttonSelectedColor;
            }
            &.loading {
                &:before, &:after {
                    display: none!important;
                }
                    
            }
            &[disabled]{
                cursor: initial; 
                background-color:rgba(0,0,0,0.1);
                opacity: 0.5;
                overflow: hidden;
                &:before, &:after {
                    height:1px;
                    width:100%;
                    top:50%;
                    left:0%;
                    transform: translateY(-50%) translateX(-50%) scale(2,0);
                    content: '';
                    display: block;
                    position: absolute;
                    transform-origin: center;
                    background-color:rgba(0,0,0,0.3);
                    
                }
                &:before {
                    transform: rotate(-45deg);
                }
                 &:after {
                    transform: rotate(45deg);
                }
            }
            @if $actionBorder == false{
                border:0 none!important;        
            }
            @if $actionRounded == true{
                border-radius: $actionRoundedRadius;
            }    
            /*@if $actionShadow == true{
                box-shadow: $actionShadowRadius;
            }
             &:hover {
                @if $actionShadow == true{
                    box-shadow: $actionShadowRadiusHover;
                }
            }*/
        }
    }
}
@media all and (min-width:321px) {
    .size-list {	
        li {
            min-width: 20%;
            button {
                height: 56px;
            }
        }
    }
}
@media all and (min-width:414px) {
    .size-list {	
        li {
            min-width: 64px;
            button {
                height: 56px;
            }
        }
    }
}