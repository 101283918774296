@import "../../global.scss";

div.Bookmarks {
    &.full {
        padding-top: 20px !important;
        .content-scroll {
           
            > div > h2 {
                display: none;
            }
            .calendarMonth {
                padding: 0;
            }
            ul{
                padding: 0;
            }
        }
    }
    &.nav-left {
        .content-scroll {
            padding: 75px 0 0 35px;
            text-align: right;
        }
    }
    &.nav-bottom {
        .content-scroll {
            padding-bottom: $sidebarHeight;
            text-align: right;
        }
    }
    h2 {
        padding-top: 10px;
        padding-right: 15px;
        font-size: $fontSizeNormal;
    }
    .no-bookmarks {
        position: relative;
        height: 100%;
        .background {
            font-size: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-family: $fontBold;
            color: $upcomingLaunchesStripeColour;
        }
        .overlay {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
        }
        text-align: center;
    }
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
        display: block;
    }
    ul {
        text-align: left;
        padding: 15px 7.5px 5px 2.5px;
        overflow: hidden;
        font-size: 0;
        > span li {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding: 5px 7.5px 15px;
            font-size: initial;
        }
    }
    &:first-of-type {
        padding-top: 0;
        ul:first-of-type {
            li.calendarMonthLabel {
                padding-top: 10px;
            }
        }
    }
}
@media all and (min-width: 600px) {
    div.Bookmarks {
        li.product {
            width: 50%;
            .overlay {
                padding-left: 4vw;
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
@media all and (min-width: 320px) and (orientation: landscape) {
    div.Bookmarks {
        li.product {
            width: 33.33%;
            .overlay {
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
@media all and (min-width: 600px) and (orientation: landscape) {
    div.Bookmarks {
        li.product {
            width: 50%;
            .overlay {
                padding-left: 4vw;
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
@media all and (min-width: 800px) and (orientation: landscape) {
    div.Bookmarks {
        li.product {
            width: 20%;
            .overlay {
                .countdown {
                    font-size: $fontSizeXXLarge * 2.5;
                }
            }
        }
    }
}

@media all and (min-width: 1024px) and (orientation: portrait) {
    div.Bookmarks {
        li.product {
            width: 50%;
            .overlay {
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
@media all and (min-width: 1366px) and (orientation: landscape) {
    div.Bookmarks {
        li.product {
            width: 16.66%;
            .overlay {
                .countdown {
                    font-size: $fontSizeXXLarge * 1.5;
                }
            }
        }
    }
}
