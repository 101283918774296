@import "../../../global.scss";

div.Marketing-Preferences {
	.form-horizontal {
		padding-bottom: 0px;
	}

	img{
		height: 80px;
	}
	hr{
		background-color: $fontColorLight;
		border-width: 2px;
	}

	.gender-input{
		margin-top: -15px;
	}

	.dropdowns {
		.dateInput {
			display: flex;
			margin-top: -20px;
			.mounth{
				flex-grow: 1;
			}
			span{
				padding: 0 2px 0 2px;
				>div>div>div>div{
					padding-right: 45px !important;
				}
			}
			> .active {
				label {
					display: none;
				}
			}
			label {
				color: rgba(0, 0, 0, 0.3) !important;
			}
		}

		label {
			color: #000 !important;
			font-size: 18px;
			font-weight: normal;
			position: absolute;
			pointer-events: none;
			left: 0px;
			top: 22px;
			font-size: inherit;
			transition: $transitionAll;
			top: 0;
			z-index: 0;
		}
	}
}

@media all and (min-width: 768px) {
	.dateInput {
		flex-direction: column;
	}
}