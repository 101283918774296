@import "../../global.scss";

div.cart-totals {
	background-color: #EEEEEE;
	margin: 0 -8vw 10px;
	padding: 30px 8vw;

	ul {
		padding: 10px 0 0;
        margin: 0;
        
		li {
			list-style-type: none;
            margin: 0;
            padding: 0;
		}
	}
	.totals-container {
		box-sizing: border-box;
		display: flex;
		margin: 0px;
		justify-content: flex-end;
        padding: 5px 0 0;
		h4, h5 {
			font-weight: bold !important;
			font-family: "fontFamilyBold";
		}
		h4 {
		}
		.totals-split {
			max-width: 50%;
			min-width: 50%;
			flex-grow: 1;
			padding: 0 5px;
			&:first-child {
				text-align: left;
				padding-left: 0!important;
			}
			&:last-child {
				text-align: right;
				padding-right: 0!important;
			}
		}
	}
}
@media all and (min-width:768px){
	div.Checkout {
		div.cart-totals {
			margin-left: -30vw;
			margin-right: -30vw;
			padding-left: 30vw;
			padding-right: 30vw;
		}
	}
}

