@import "../../../../global.scss";
.badge-group {
    .badge-scroll {
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        .badge {
            background-color: $fontColorLighter;
            border-radius: 100%;
            margin: 10px;
            position: relative;

            &:last-of-type {
                >div{
                    width: 70px;
                    height: 70px;
                    text-align: center;
                    color: $fontColorLight;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    display: flex;
                    >div{
                        font-size: 15px;
                        text-align: center;
                    }
                }
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:before,
                &:after {
                    border-radius: 5px;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: inherit;
                    z-index: -1;
                    transform: rotate(30deg);
                }

                &:after {
                    transform: rotate(-30deg);
                }
            }

            &.inactive {
                img {
                    opacity: 0;
                }
            }
        }
        img {
            width: 70px;
            height: 70px;
            object-fit: contain;
        }
    }
}
