@media (min-width: 800px) and (orientation: landscape) {
    div.Calendar li.product {
        width: 25%;
    }
}

div li.product .image .launchDateMini {
    width: 50px;
    padding-bottom: 50px;
    left: 0;
}

div.Calendar ul ul{
    display: flex;
    flex-wrap: wrap;
}

div.Calendar ul ul > li{
    display: flex;
    >a{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

div.Calendar li.product .productInfo {
    flex-grow: 1;
    background-color: #f5f5f5;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .itemFooter{
        margin-top: auto;
    }
    .productDetails{
        padding: 0 5px;
        overflow: hidden;
        > .title {
            font-weight: bold;
        }
    } 
    .launchInfo {
        .actions {
            display: none;
        }
        display: inline-block;
        position: relative;
        background-color: white;
        padding: 6px;
        margin: 5px;
        width: 80%;
        border-radius: 4px;
        background-color: #fff;
    }
    hr {
        display: none;
    }
    .subtitle-price {
        .price {
            display: none;
        }
    }
}
