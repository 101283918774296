div.Delete-Preferences {
    ul.dashed {
        margin: 0;
        list-style-type: none;
        > li {
            margin-bottom: 8px;
            text-indent: -5px;
        }
        > li:before {
            content: "-  ";
            text-indent: -5px;
        }
    }
    .content-scroll{
        padding-bottom: 160px !important;
    }
}