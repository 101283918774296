@import "../../../../global.scss";

@media all and (min-width: 768px) {
div.Preferences.Withdraw {
	.content-scroll {
		padding-left: 30vw !important;
		padding-right: 30vw !important;
	}
	.form-action {
		padding-left: 30vw !important;
		padding-right: 30vw !important;
	}
}
}
