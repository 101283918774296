@import "../../global.scss";

.launchDateMini {
    width: 38px;
    padding-bottom: 38px;
    position: absolute;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    background-color: $calendarBackground;
    z-index: 100;
    .content {
        position: absolute;
        color: $colorAccent;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .mount h4 {
            font-size: 10px;
            text-transform: uppercase;
        }
        .date h4 {
            font-size: 16px;
        }
    }
}
