@import "../../global.scss";

.promoPopupWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    background-color: $overlayBackground;
    opacity: 1;
    .promoModal {
        li {
            list-style: none;
        }
        height: 90vh;
        width: 90vw;
        background-color: white;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        .modalHeader {
            height: 50px;
        }
        .productList {
            overflow-x: scroll;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 5px;
            .product {
                padding: 5px;
                width: 50%;
            }
        }
    }

    .close {
        position: absolute;
        font-size: $fontSizeLarge;
        padding: 20px;
        line-height: normal;
        text-indent: 0;
        background-color: transparent;
        color: $fontColorDefault;
        font-weight: initial;
        top: 10px;
        right: 15px;
        i {
            display: none !important;
        }
        &:after {
            @include cross(plus);
            color: $productColor;
            border-color: $productColor !important;
            top: 20px;
            right: 20px;
            margin-top: -20px;
            margin-right: -20px;
            transform-origin: center;
            transform: rotate(0);
            position: absolute;
            font-size: 25px;
        }
    }
}

//TRANSITIONS
.promo-modal-enter {
    opacity: 0.01;
}

.promo-modal-enter.promo-modal-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.promo-modal-leave {
    opacity: 1;
}

.promo-modal-leave.promo-modal-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
}
