@import "../../global.scss";

.page-wrapper > span > div.Product > .content-scroll {
    padding-top: 70px !important;
    .product-details-header {
        .titleBlock {
            line-height: 1.3;
            font-family: $fontFamilyBold;
        }
        padding-bottom: 5px;
    }
    .launchDateMini {
        display: none;
    }
    .product-details>div{
        margin-bottom: 15px;
    }
    .underlinedTitle{
        width: 100%;
    }
    .product-details .inlineContainer{
        grid-template-columns: none;
    }
    .image-gallery-bullets{
        bottom: 0px;
    }
}
