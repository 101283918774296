@import "../../global.scss";

div.privacy-notice {
    background-color: transparent;
    max-height: 50px;
    margin: 20px 0 20px;
    span.link {
        text-decoration: underline;
    }
}
div.Checkout.Order div.privacy-notice {
    background-color: transparent;
}
div.Checkout .content-scroll {
    padding: 20px 8vw 20px;
}