@import "../../global.scss";

.form-header {
    h3 {
        font-weight: initial;
        font-family: $fontBold;    
    }
}

.journeyScreen {   
    .content-scroll > .form-header:first-of-type h3:before {  
        margin-right: 10px;
        display: inline-block;
        opacity: 0.6;
    }
    > .Delivery-Preferences .content-scroll > .form-header:first-of-type h3:before {
        content: '1/4';    
    }
    > .Billing-Preferences .content-scroll > .form-header:first-of-type h3:before {
        content: '2/4';    
    }
    > .Payment-Preferences .content-scroll > .form-header:first-of-type h3:before {
        content: '3/4';    
    }
    > .Notification-Preferences .content-scroll > .form-header:first-of-type h3:before {
        content: '4/4';    
    }
}