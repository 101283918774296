@import "../../../../global.scss";

div.Campaign .ContentPage {
    padding-top: 70px;
}

div.Campaign.left .borders {
	margin-left: 40px;
}

div.Campaign.bottom .content-scroll {
	padding-bottom: $sidebarHeight;
}

div.ContentPage {
	font-size: 14px !important;
	background-color: rgba($orderBackground, 0);

	.borders {
		margin-left: 20px;
		margin-right: 20px;
	}
	

	.content-scroll {
		.no-margin {
			margin-bottom: -20px;
		}
		.image-wrapper img{
			width: 100%;
		}
	}
}