@import "../../../global.scss";

div.Notification-Preferences {
	.notifyTimeBefore {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: -10px;
        overflow: hidden;
        height: 51px;
        margin-left: -10px;
        max-width: 320px;
        .time {
            flex-direction: row;
            flex-shrink: 1;
            button {
                &:first-of-type + div {
                    width:40px!important;
                    display: inline-block!important;
                    vertical-align: top;
                    text-align: center;
                    position: relative;
                    top:-11px;
                    background-color: transparent!important;
                    input, .input {
                        text-align: center;
                    }
                }
            }
            .group {
                 top:0!important;
            }
        }
        .interval {
            flex-grow: 1;
            // overwrite material 
            >div >div > div {
                div:nth-child(2) {
                    font-size: $fontSizeSmall!important;
                    width: 100%;
                    padding-right:0!important;
                    color:  $fontColorDefault!important;   
                }
                button {
                    z-index: 1;
                    padding: 0!important;    
                    top: 7px!important;
                    height: 28px!important;
                    width:28px!important;
                }
                svg {
                    margin-top:3px;
                }
            }
            select {
            }
            > div {
                top: -14px!important;
                > div {
                    margin-top: 17px!important;
                }

            }
        }
        .remove, .decrement, .increment {
            display: inline-block!important;
            min-width: 0!important;
            vertical-align: top;
            width:40px;
            height: 40px!important;
            line-height: 40px;
            text-align: center;
            font-family: $fontFamilyBold!important;
            color:$fontColorDefault!important;
            border-radius: 0;
            position: relative;
            top:8px;
                background-color: transparent!important;
            * {
                font-size: inherit!important;
            }
            text-align: center;
            span {
                font-size: 0!important;
                width:10px!important;
                height: 10px!important;
                display: block;
                margin:0 auto;
                padding: 0!important;
                transform-origin: center;

            }
        }
        .remove {
            padding-top: 14px;
            span {
                @include minusplusremove(remove);
            }
        }
        .decrement {
            > div  {
                @include minusplusremove(minus);
                height: 10px;
                width:10px;
                margin: 15px;
                > span {
                    display: none!important;
                }
            }
            &:disabled {
                > span {
                    display: block;
                    @include minusplusremove(minus);
                    height: 10px;
                    width:10px;
                    margin: 15px;
                    opacity: 0.4;
                }
            }
        }
        .increment {
             > div  {
                @include minusplusremove(plus);
                height: 10px;
                width:10px;
                margin: 15px;
                > span {
                    display: none!important;
                }
            }
            &:disabled {
                > span {
                    display: block;
                    @include minusplusremove(plus);
                    height: 10px;
                    width:10px;
                    margin: 15px;
                    opacity: 0.4;
                }
            }
        }
	}
    .addNotification {
        padding: 15px 0;
        font-family: $fontFamilyBold!important;
    }
    .form-header-split {
        img, .form-header {
            display: inline-block;
            vertical-align:top;
            h3 {
                font-size: $fontSizeMedium * 0.9;
                font-family: $fontFamilyMedium;
            }
        }
        ~ .form-header-split {
            margin-top: 20px;
        }
        img {
            width:16px;
            height:16px;
            display: inline-block;
            margin-right: 4px;
            filter: brightness(100%);
            -webkit-filter: brightness(100%);
            position: relative;
            top: 0px;
        }
    }
    
    ul li {        
        span {
            display: inline-block;
            vertical-align: middle;
            
        }
        ~ li {
            padding-top: 10px;
        }
    }
}