@import "../../global.scss";

div.Checkout {
    .launchButton {
        background-color: $actionBackground;
        color:$actionColor;
        font-size: 16px;
        width:100%;
        padding:15px;
        margin-bottom: 15px;
       
    }
    .missing {
        line-height: 3;
        text-decoration: underline;
    }

    .content-scroll {
        padding: 20px 8vw 100px;
        span.link {
            text-decoration: underline;
        }
        .checkout-header {
            .logo {
                text-align: right;
                height: 0px;
                position: relative;
                right: -15px;
                top: -44px;
                img {
                    height: 24px;
                }
            }
        }
        .logo {
            right: 0px;
            top: 0px;
        }
        .content-scroll-fix {
            box-sizing: initial;
            min-height: calc(100% + 1px);

            > span {
                display: block;
            }
        }
    }
    .miniCartItems {
    }
    div.storeSelection {
        padding: 10px 0 15px;
        label {
            font-family: $fontFamilyMedium;
            font-weight: $fontWeightDefault;
            margin: 0px 0 0 0 !important;
        }
        div.closeButton {
            padding: 15px 40px 15px 15px;
            margin: 10px 0;
            border: $borderDefault;
            position: relative;
            > a {
                width: 10px;
                height: 10px;
                display: block;
                @include minusplusremove(remove);
                position: absolute;
                right: 12px;
                top: 50%;
                margin-top: -5px;
            }
        }
    }
    .form-suggestion {
        ul {
        }
    }
    .useBilling {
        margin: -15px 0 15px;
    }
    .boosted {
        color: $colorAccent;
        box-shadow: 0 0 15px $colorAccent;
        .button-contents ::after {
            border-color: $colorAccent !important;
        }
    }
}

// TRANSITIONS
.checkout-block-enter,
.checkout-block-leave.checkout-block-leave-active {
    max-height: 0px;
    transition: max-height 200ms ease;
    overflow: hidden;
}
.checkout-block-enter.checkout-block-enter-active,
.checkout-block-leave {
    max-height: 500px;
    overflow: hidden;
    transition: max-height 200ms ease;
}

@media all and (max-width: 767px) {
    div.Checkout {
        .usp{
            display: none;
        }
        .right {
            .desktop {
                display: none;
            }
        }
    }
}

@media all and (min-width: 768px) {
    div.Checkout {
        .mobile {
            display: none;
        }
        .left{
            padding-right: 15px;
            flex-grow: 1;
        }
        .right{
            padding-left: 15px;
            width: 40%;
            min-width: 300px;
            div.cart-totals{
                padding: 20px;
                margin: 0;
            }
        }
        .usp{
            position: relative;
            padding: 0;
            height: auto;
            width: 100%;
            display: flex;
            padding-bottom: 20px;
            flex-direction: row-reverse;
            h4{
                margin-left: 0 !important;
                margin-right: 10px;
                width: 170px;
                align-self: center;
                height: auto;
            }
            .countdown{
                flex: auto;
                height: 30px;
            }
        }
        .content-scroll-fix {
            display: flex;
            flex-direction: row-reverse;
        }
        .content-scroll {
            padding-left: 10vw !important;
            padding-right: 10vw !important;
        }
        &.Payment, .loginSignup{
            .content-scroll {
                padding-left: 30vw !important;
                padding-right: 30vw !important;
            }
        }
        .loginSignup .form-action,
        #adyen-encrypted-form .form-action {
            padding-left: 30vw;
            padding-right: 30vw;
        }
    }
}
