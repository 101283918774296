@import "../../global.scss";

.splashLogin {
    .splashActions > a .button-contents{
        text-transform: uppercase;
    }
    .description {
        font-family: $fontFamilyBold;
        font-size: 10pt;
    }
    .splashFooter {
        > span {
            display: none;
        }
    }
}

@media (max-width: 820px) {
    div.splashLogin {
        background-image: url(./assets/img/login/splash-bg-mobile.jpg) !important;
        .splashHeader {
            padding-top: 25px;
            padding-left: 25px;
        }
        .splashActions{
            >a{
                margin-bottom: 12px;
                padding: 10px 13px !important;
            }
            height: 110px;
            .action{
                font-size: 100% !important;
            }
        }
    }
}
