@import "../global.scss";

@if $materialOverwrite == true {
    input,
    .input,
    select,
    textarea {
        color: $fontColorDefault;
        font-size: $fontSizeNormal;
        transition: $transition;
        text-indent: 0.01px;
        border-collapse: collapse;
        -webkit-appearance: none;
        border-radius: 0;
        vertical-align: top;
        border: 0 none;
        @if $inputBorders == true {
            border: $borderDefault;
        }
        @if $inputMaterialStyle == true {
            padding: 0;
            border-bottom: $borderInput;
            font-size: $fontSizeNormal;
            font-family: $fontBold;
            background-color: transparent;
            height: 30px;
        } @else {
            padding: 0 15px;
            background-color: $inputBackground;
            height: 36px;
        }
        @if $inputPlaceholders == false {
            &::placeholder {
                font-size: 0;
                color: transparent;
            }
        }
        &:focus {
            border-color: $colorBorderFocus;
        }
        &:-webkit-autofill {
            box-shadow: 0 0 0px 1000px $accountBackground inset;
            color: transparent;
            background-color: transparent;
            transition: all 0s !important;
        }
    }
} @else {
}
input[type="text"] {
    -webkit-user-select: text;
}

input[type="radio"] {
    -webkit-appearance: radio;
    height: auto;
}
input[type="checkbox"] {
    -webkit-appearance: checkbox;
    height: auto;
}
input[name="postcode"] {
    width: 50% !important;
    text-transform: uppercase;
}
input[name="quantity"] {
    .invalid {
        color: $colorError !important;
    }
}
.form-horizontal {
    padding: 10px 0 15px 0;
    > p {
        max-width: 260px;
        width: 65%;
        &.intro {
            padding-bottom: 10px;
        }
    }
    .form-group {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        &.postcode {
            width: 50%;
            max-width: 150px;
        }
        > div {
            width: 100% !important;
            font-family: inherit !important;
            height: 60px !important;
            color: inherit !important;
            &.group {
                position: relative;
                height: 55px !important;
                input,
                .input,
                select {
                    font-size: $fontSizeNormal;
                    font-family: inherit !important;
                    padding: 0;
                    display: block;
                    width: 100%;
                    border-bottom: $borderInput;
                    height: 26px;
                    margin-top: 0;
                    overflow: hidden;
                    margin-top: 12px !important;
                    padding-top: 8px !important;
                    z-index: 1;
                    &[required] {
                        ~ label:after {
                            content: " *";
                            color: $colorError;
                        }
                    }
                    &.invalid {
                        color: $colorError !important;
                        border-bottom-color: $colorError;
                        ~ .bar:before,
                        ~ .bar:after {
                            content: "";
                            height: 2px;
                            width: 0;
                            bottom: 0px;
                            position: absolute;
                            transition: $transitionAll;
                            background-color: $colorError;
                        }
                    }
                }
                /* LABEL ======================================= */
                label {
                    color: #000;
                    font-size: 18px;
                    font-weight: normal;
                    position: absolute;
                    pointer-events: none;
                    left: 0px;
                    top: 22px;
                    font-size: inherit;
                    transition: $transitionAll;
                    top: 0;
                    padding-top: 20px;
                    z-index: 0;
                }
                input:placeholder-shown,
                .input:placeholder-shown {
                    ~ label {
                        top: 0px;
                        font-size: $fontSizeNormal !important;
                        padding-top: 20px;
                        padding-bottom: 0;
                    }
                }
                input:not(:placeholder-shown),
                input:focus,
                .input:not(:placeholder-shown),
                .input:focus {
                    ~ label {
                        top: 0px;
                        font-size: $fontSizeSmall !important;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }

                /* BOTTOM BARS ================================= */
                .bar {
                    position: relative;
                    display: block;
                    width: 100%;
                }
                .bar:before,
                .bar:after {
                    content: "";
                    height: 2px;
                    width: 0;
                    bottom: 0px;
                    position: absolute;
                    transition: $transitionAll;
                    background-color: $colorBorderFocus;
                }
                .bar:before {
                    left: 50%;
                }
                .bar:after {
                    right: 50%;
                }
                /* active state */
                input:focus ~ .bar:before,
                input:focus ~ .bar:after,
                .input:focus ~ .bar:before,
                .input:focus ~ .bar:after {
                    width: 50%;
                }
                /* HIGHLIGHTER ================================== */
                .highlight {
                    display: none !important;
                }
                /* active state */
                input:focus ~ .highlight,
                .input:focus ~ .highlight {
                    -webkit-animation: inputHighlighter 0.3s ease;
                    -moz-animation: inputHighlighter 0.3s ease;
                    animation: inputHighlighter 0.3s ease;
                }
                /* ANIMATIONS ================ */
                @-webkit-keyframes inputHighlighter {
                    from {
                        background: #5264ae;
                    }
                    to {
                        width: 0;
                        background: transparent;
                    }
                }
                @keyframes inputHighlighter {
                    from {
                        background: #5264ae;
                    }
                    to {
                        width: 0;
                        background: transparent;
                    }
                }
                &.invalid {
                    input,
                    .input,
                    select {
                        color: $colorError !important;
                        border-bottom-color: $colorError;
                    }
                    .bar:before,
                    .bar:after {
                        content: "";
                        height: 2px;
                        width: 0;
                        bottom: 0px;
                        position: absolute;
                        transition: $transitionAll;
                        background-color: $colorError;
                    }
                }
            }
        }
        &.form-text {
            p {
                padding-top: 8px;
            }
        }
        &.form-select {
            &:after {
                @include chevron(down);
                right: 5px;
                top: 10px;
            }
        }
    }
    & + .form-horizontal {
        padding-top: 0;
    }
    &.form-options {
        ul {
            list-style: none;
            padding: 0;
            margin: -10px 0 0;
            li {
                ~ li {
                    border-top: $borderDefault;
                }
                + li:empty ~ li {
                    border-top: $borderDefault;
                }
                &:empty {
                    display: none;
                    + li {
                        border-top: none;
                        &:empty {
                            + li {
                                border-top: none;
                            }
                        }
                    }
                }

                &.close {
                    .price {
                        span {
                            width: 10px;
                            height: 10px;
                            display: block;
                            float: right;
                            @include minusplusremove(remove);
                        }
                    }
                }
            }
        }
    }

    &.list-links {
        ul {
            list-style: none;
            padding: 0;
            margin: -5px 0 0;
            li {
                label {
                    flex-direction: column;
                    text-align: left;
                    display: block;
                    position: relative;
                    padding-right: 20px;
                    &:before {
                        @include chevron(right);
                        border-color: initial;
                        top: 50%;
                        right: 0;
                        margin-top: -3px;
                    }
                }
                h4 {
                    font-family: $fontFamilyBold;
                }
                &:empty {
                    display: none;
                }
            }
        }
    }
    .order-details {
        > h4 {
            font-family: $fontFamilyBold;
            padding-bottom: 5px;
        }
        p {
            margin: 0;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: table-row;
                .order-item-image {
                    width: 150px;
                    img {
                        display: block;
                        width: 100%;
                        min-height: 150px;
                    }
                }
                .order-item-info {
                    padding-top: 10px;
                    .bottom-block {
                        display: flex;
                        padding-top: 10px;
                        margin-top: 10px;
                        border-top: $borderDefault;
                        .price {
                            margin-left: auto;
                        }
                    }
                }
                .order-item-remove {
                    align-items: flex-end;
                    display: flex;
                    > span {
                        display: block;
                        padding: 15px 0;
                        margin-bottom: -15px;
                        text-decoration: underline;
                    }
                }
                h4 {
                }
                p.price {
                    font-family: $fontFamilyBold;
                    span {
                        font-family: inherit;
                    }
                }
            }
        }
        ~ .order-details {
            padding-top: 15px;
        }
        &.order-totals {
            h3 {
                font-family: $fontFamilyBold;
                text-transform: uppercase;
            }
        }
        &.order-preview {
            background-color: rgba(255, 255, 255, 0.5);
            margin-top: -10px;
            h3 {
                padding-bottom: 15px;
            }
        }
    }
}

.check-option {
    input,
    .input {
        display: none;
        &:checked {
            + label {
                span.check-box {
                    border-color: $colorBorderFocus;
                    box-shadow: 0 0 4px 20px inset rgba(0, 0, 0, 0);
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
    label {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        padding: 15px 0;
        margin: 0;
        font-weight: normal;
        span {
            flex-grow: 1;
            flex-shrink: 1;
            text-align: left;
            &.check-box {
                width: 20px;
                height: 20px;
                max-width: 20px;
                min-width: 20px;
                border-radius: 100%;
                border: $borderDefault;
                &:before {
                    width: 10px;
                    height: 10px;
                    display: block;
                    background: $colorSecondary;
                    content: "";
                    margin: 3px;
                    border-radius: inherit;
                    opacity: 0;
                    transition: $transition;
                }
                &.tick {
                    width: 20px;
                    height: 20px;
                    max-width: 20px;
                    border-radius: initial;
                    border: $borderDefault;
                    &:before {
                        position: relative;
                        top: -2px;
                        left: 2px;
                        content: "";
                        display: block;
                        background-color: initial;
                        width: 6px;
                        height: 12px;
                        border: solid #000;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                    }
                }
            }
            &.name {
                padding: 0 10px;
                flex-grow: 0;
                .description {
                    padding-top: 2px;
                    display: block;
                    font-family: $fontFamilyMedium;
                    &:empty {
                        display: none;
                    }
                }
            }
            &.price {
                text-align: right;
                font-family: $fontBold;
            }
        }
    }
    ~ .check-option {
        border-top: $borderDefault;
    }
}

.no-bold {
    input,
    .input {
        &:checked {
            + label {
                font-family: $fontFamilyMedium;
            }
        }
    }
}

.privacy-block {
    position: absolute;
    bottom: 0;
}

.form-action {
    position: fixed;
    padding: 20px 8vw 20px;
    background: $accountBackground;
    background: -webkit-linear-gradient(
        top,
        rgba($accountBackground, 0) 0%,
        rgba($accountBackground, 1) 18%,
        rgba($accountBackground, 1) 100%
    );
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px;
    z-index: 2;
    .inline {
        display: flex;
    }

    &.not-fixed {
        position: initial;
        padding: initial;
        margin-top: 10px;
        background-color: initial;
    }

    .split {
        flex-grow: 1;
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        &:first-child {
            padding-left: 0 !important;
        }
        &:last-child {
            padding-right: 0 !important;
        }
        .action {
            max-width: 50%;
            min-width: 50%;
        }
    }

    .action-split {
        max-width: 50%;
        min-width: 50%;
        flex-grow: 1;
        padding: 0 5px;
        &:first-child {
            padding-left: 0 !important;
        }
        &:last-child {
            padding-right: 0 !important;
        }
    }
    &.final {
        .action-split {
            max-width: auto;
            min-width: auto;
            flex-grow: 1;
            padding: 0 5px;
            &.pay {
                max-width: 65%;
                min-width: 65%;
            }
        }
    }
    button {
        width: 100%;
        display: block;
        img {
            max-height: 20px;
        }
        &.cancel,
        &.forgotten {
            border: 0 none;
            max-height: 60px;
            width: auto;
            height: 50px;
            background: transparent;
            color: $accountColor;
            padding: 0 8vw;
            margin-left: -8vw;
        }
        &.cancel {
            background-color: mix($accountBackground, black, 90%);
            padding: 0 15px;
            height: 36px;
            margin-left: 0;
            margin-top: 10px;
        }
        &.action {
            text-align: left;
            display: block;
            .button-contents {
                position: relative;
                &::after {
                    position: absolute;
                    margin: auto;
                    border-color: initial !important;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    @include chevron(right);
                }
            }
            &:disabled {
                background-color: mix($accountBackground, black, 90%);
                color: mix($accountBackground, black, 50%) !important;
            }
        }
        &.logout {
            background-color: $actionLogoutBackground;
            color: $actionLogoutColor;
        }
    }
}
.payment-marks {
    height: 24px;
    background-image: url(../assets/img/payments/payment-marks.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin: 5px 0 10px 0;
}
.ios .payment-marks {
    background-image: url(../assets/img/payments/payment-marks-ios.png);
}

a.link {
    text-decoration: underline;
}

#customCard-container {
    display: flex;
    flex-direction: column;

    .form-action.notFixed {
        position: relative;
    }

    .customCard_exp {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    input[name="holderName"] {
        font-size: 100%;
        font-family: inherit !important;
        padding: 0;
        padding-bottom: 12px;
        display: block;
        width: 100%;
        border-bottom: 1px solid #4a4a4a;
        height: 26px;
        margin-top: 0;
        overflow: hidden;
        margin-top: 12px !important;
        padding-top: 8px !important;
        z-index: 1;

        &::placeholder {
            font-size: 13px; /* Set the color of the placeholder text */
            color: #afaeae !important;
            font-family: $fontFamilyThin;
            font-weight: bold;
        }
    }

    div {

        display: flex;
            flex-direction: column;

        label {
            display: flex;
            flex-direction: column;
            padding: 20px 0;

            .customCard_title {
                &:after {
                    content: " *";
                    color: #c91515;
                }
            }

            span[data-cse="encryptedCardNumber"] {
                height: 30px;
                max-width: 100%;
                border-bottom: 1px solid #4a4a4a;
                display: flex;
            }

            span[data-cse="encryptedExpiryDate"] {
                height: 30px;
                max-width: 50px;
                border-bottom: 1px solid #4a4a4a;
                display: flex;
            }

            span[data-cse="encryptedSecurityCode"] {
                height: 30px;
                max-width: 50px;
                border-bottom: 1px solid #4a4a4a;
                display: flex;
            }
        }
    }
}

.RECURRING #customCard-container > div > div > label {
    padding: 0;
}

.RECURRING #customCard-container {
        max-height: 80px;
}
