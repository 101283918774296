@import "../../global.scss";

.loyaltyNotification {
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 101;
    display: flex !important;
    flex-direction: column;
    padding: 8vw 8vw 20px;
    background: no-repeat top;
    background-size: cover;
    text-align: center;
    color: $fontColorLight;
    justify-content: center;
    align-content: center;
    font-size: 120%;
    transition: $transitionAll;

    &.notification-achievement {
        img {
            width: 50%;
        }
    }

    u {
        padding-top: 5px;
    }

    h1 {
        padding: 15px 0;
    }
    img {
        width: 60%;
    }

    .shareButton {
        padding-top: 15px;
        color: $fontColorLighter;
        div {
            display: inline-block;
            padding: 8px;
            padding-top: 5px;
            border-radius: 100px;
            background-color: $fontColorLight;
        }
    }

    .form-action {
        background: transparent !important;
    }
}

