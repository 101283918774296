@import "../../../../global.scss";

div.membership-benefit {
    color: $fontColorLight;
    h1 {
        padding-top: 10px;
        padding-bottom: 5px !important;
        font-size: 16px;
    }
    .benefit {
        overflow: hidden;
        img {
            width: 35%;
            max-height: 175px;
            float: right;
        }
        &.expand {
            transition: $transitionAll;
            max-height: 250px;
        }
        &.contract {
            transition: $transitionAll;
            max-height: 0;
        }
    }
    span{
        padding-right: 2px;
    }
    .more {
        margin-left: 3px;
        display: inline-block;
        transition: all 0.5s;
        margin: auto;
        width: 10px;
        height: 10px;
        border-top: $fontColorLight 2px solid;
        border-right: $fontColorLight 2px solid;
        transform: rotate(135deg);
        &.contract {
            transform: rotate(-45deg);
        }
    }
}
